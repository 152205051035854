import { Box, Skeleton, TableBodyCell, TableRow, pxToRem } from '@pocketlaw/tetris';
export function SkeletonRow(props) {
    const { showSelectCell } = props;
    const getNumberBetween = (min, max) => pxToRem(Math.floor(Math.random() * (max - min + 1) + min));
    const nameWidth = getNumberBetween(120, 220);
    const updatedWidth = getNumberBetween(60, 80);
    const creatorWidth = getNumberBetween(80, 100);
    return (<TableRow>
      <TableBodyCell headers="cell:name">
        <Box alignItems="center" gap="md">
          <Skeleton width={pxToRem(24)} height={pxToRem(24)} borderRadius="md"/>
          <Skeleton width={nameWidth} height={pxToRem(16)} borderRadius="md"/>
        </Box>
      </TableBodyCell>
      <TableBodyCell headers="cell:updated">
        <Skeleton width={updatedWidth} height={pxToRem(16)} borderRadius="md"/>
      </TableBodyCell>
      <TableBodyCell headers="cell:owner">
        <Box alignItems="center" gap="md">
          <Skeleton width={pxToRem(24)} height={pxToRem(24)} borderRadius="circle"/>
          <Skeleton width={creatorWidth} height={pxToRem(16)} borderRadius="md"/>
        </Box>
      </TableBodyCell>
      {showSelectCell && <TableBodyCell headers="cell:select"/>}
    </TableRow>);
}
