import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Loader } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm/useEsigningSignatoriesFormField';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { FormStatus } from 'app/domains/esigning/utils';
import { usePDFData } from 'shared/domains/documents';
import { CreditsErrorAlert } from './CreditsErrorAlert';
import { NoCreditsAlert } from './NoCreditsAlert';
import { PasswordProtectedPdfAlert } from './PasswordProtectedPdfAlert';
import { RestrictedPermissionsPdfAlert } from './RestrictedPermissionsPdfAlert';
import { SigningOrderAlert } from './SigningOrderAlert';
import { useGetAvailableEsigningCredits } from './useGetAvailableEsigningCredits';
export function SendButton() {
    const { signatories } = useEsigningSignatoriesFormField();
    const { properties } = useEsigningProviderMetadata();
    const { showCreditBalance } = properties;
    const { submitForm, isValid, status } = useFormikContext();
    const { submittingCreation } = useEsigning();
    const isUploading = status === FormStatus.Uploading;
    const pdfData = usePDFData();
    const { availableCredits, loading: loadingCredits, error: creditsError, } = useGetAvailableEsigningCredits();
    const hasNoCredits = showCreditBalance && availableCredits === 0;
    const hasRestrictedPermissions = (pdfData === null || pdfData === void 0 ? void 0 : pdfData.hasRestrictedPermissions) || false;
    const isPasswordProtected = (pdfData === null || pdfData === void 0 ? void 0 : pdfData.passwordProtected) || false;
    const submitDisabled = isPasswordProtected ||
        submittingCreation ||
        !isValid ||
        loadingCredits ||
        hasNoCredits ||
        !signatories.length ||
        isUploading;
    const getButtonContent = () => {
        if (submittingCreation) {
            return (<Fragment>
          <Trans comment="Label for loading when sending document for e-signing">
            Sending for e-signing
          </Trans>
          <Loader />
        </Fragment>);
        }
        if (isUploading) {
            return (<Fragment>
          <Trans comment="Label for the send for esigning button when an attachment is being uploaded">
            Uploading attachment. Wait…
          </Trans>
          <Loader />
        </Fragment>);
        }
        return <Trans comment="Label for sending document for e-signing">Send for e-signing</Trans>;
    };
    return (<Fragment>
      {isPasswordProtected && <PasswordProtectedPdfAlert />}
      {hasRestrictedPermissions && !isPasswordProtected && <RestrictedPermissionsPdfAlert />}
      {showCreditBalance && (<Fragment>
          {!loadingCredits && hasNoCredits && !creditsError && <NoCreditsAlert />}
          {!loadingCredits && creditsError && <CreditsErrorAlert />}
        </Fragment>)}
      <SigningOrderAlert />
      <Button $stretch type="submit" appearance="primary" disabled={submitDisabled} onClick={submitForm}>
        {getButtonContent()}
      </Button>
    </Fragment>);
}
