import { t } from '@lingui/macro';
import { useIsAccessTokenExpired } from 'app/domains/auth/hooks/useIsAccessTokenExpired';
import { useDocumentLocked } from 'app/domains/documents';
import { useDocumentAttachments, useGetCurrentUser, usePleditor } from 'shared/domains/documents';
import { RestrictedEditingMode, ASSISTANT_COMPONENT_NAME, } from 'shared/domains/pleditor@next';
import { PleditorError } from '../../components/PleditorError';
import { PleditorLoader } from '../../components/PleditorLoader';
import { useGetUserIdAccess } from '../../hooks/useGetUsersAccess';
import { RTCPleditor } from '../RTCPleditor';
import { SandboxPleditor } from '../SandboxPleditor';
import { useImportDocumentToCcs, useGetDocumentFeatures } from '../hooks';
import { APP_FREE_TEXT_PLUGINS } from '../plugins';
export function AppFreeTextRTCPleditor() {
    const { locked } = useDocumentLocked();
    const { sandboxMode } = usePleditor();
    const currentUser = useGetCurrentUser();
    const { attachments, loading: loadingAttachments } = useDocumentAttachments();
    const { isAccessTokenExpired } = useIsAccessTokenExpired();
    const { usersExternal, usersWithoutAccess, usersWithAccess } = useGetUserIdAccess();
    const { loading: importing, error: importError } = useImportDocumentToCcs();
    const { documentFeatures, loading: loadingFeatures, error: featuresError, } = useGetDocumentFeatures();
    const loading = importing || loadingFeatures || loadingAttachments;
    const error = importError || featuresError;
    const plugins = locked
        ? [...APP_FREE_TEXT_PLUGINS, RestrictedEditingMode]
        : APP_FREE_TEXT_PLUGINS;
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const fontItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Fonts toolbar button label', message: 'Fonts' }),
        items: ['fontSize', 'fontColor', 'fontBackgroundColor', '|', 'alignment'],
    };
    const insertItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Insert toolbar button label', message: 'Insert' }),
        items: ['link', '|', 'insertImage', '|', 'insertTable'],
    };
    const commentItems = {
        icon: false,
        label: t({ comment: 'Pleditor - Comments toolbar button label', message: 'Comments' }),
        items: ['inlineComment', '|', 'commentsArchive', '|', 'toggleComments'],
    };
    const defaultToolbarItems = [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        fontItems,
        '<->',
        insertItems,
        '|',
        commentItems,
        '|',
        'revisionHistory',
        '|',
        'editMode',
    ];
    const restrictedToolbarItems = [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        '|',
        'alignment',
        '|',
        'fontColor',
        'fontBackgroundColor',
        '<->',
        ...commentItems.items,
    ];
    const balloonToolbarItems = [
        'bold',
        'italic',
        'underline',
        '|',
        'inlineComment',
        ASSISTANT_COMPONENT_NAME,
        'selectAttachmentWidget',
    ];
    const toolbarItems = locked ? restrictedToolbarItems : defaultToolbarItems;
    if (sandboxMode) {
        return (<SandboxPleditor attachments={attachments} currentUser={currentUser} toolbarItems={defaultToolbarItems} balloonToolbarItems={balloonToolbarItems} featureAvailability={documentFeatures}/>);
    }
    return (<RTCPleditor editorName="AppFreeTextRTCPleditor" documentType="freetext" attachments={attachments} toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} featureAvailability={documentFeatures} usersExternal={usersExternal} usersWithAccess={usersWithAccess} usersWithoutAccess={usersWithoutAccess} isSessionExpired={isAccessTokenExpired} clientPlugins={plugins} locked={locked}/>);
}
