import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
import { GetFilesystemEntriesDocument, SearchFilesystemEntriesDocument, } from './query.gql';
/**
 * A hook that provides a search functionality for the FileBrowser.
 *
 * @param options.includeDocumentAi - Whether to include document AI data in the search results.
 * @param options.showSignRequestStatus - Whether to show the sign request status in the search results.
 * @returns A FileBrowserSearch object containing the search state and methods.
 */
export function useFileBrowserSearch(options) {
    const { includeDocumentAi = false, showSignRequestStatus = false } = options;
    const [searchQuery, setSearchQuery] = useState('');
    const [resultsQuery, { loading: resultsLoading, error: resultsError, called }] = useLazyQuery(SearchFilesystemEntriesDocument);
    const [entriesQuery, { data: entriesData, loading: entriesLoading, error: entriesError }] = useLazyQuery(GetFilesystemEntriesDocument);
    const searchForEntries = (newQuery, skipDocuments) => resultsQuery({
        variables: {
            query: newQuery,
            entityTypes: skipDocuments
                ? [SearchEntityTypeEnum.Folder]
                : [SearchEntityTypeEnum.Folder, SearchEntityTypeEnum.Document],
        },
        onCompleted: data => {
            entriesQuery({
                variables: {
                    includeDocumentAi,
                    showSignRequestStatus,
                    entryIds: data.search.results.map(result => result.id),
                },
            });
        },
    });
    const debouncedSearch = useDebouncedCallback((newQuery, skipDocuments) => {
        searchForEntries(newQuery, skipDocuments);
    }, 250);
    const search = (newQuery, skipDocuments = false) => {
        setSearchQuery(newQuery);
        debouncedSearch(newQuery, skipDocuments);
    };
    const clearSearch = () => setSearchQuery('');
    const searching = searchQuery.length > 0 && called;
    const loading = resultsLoading || entriesLoading;
    const error = resultsError || entriesError;
    const searchResults = (entriesData === null || entriesData === void 0 ? void 0 : entriesData.fileSystemEntries) || [];
    return {
        search,
        searching,
        searchResults,
        searchQuery,
        clearSearch,
        loading,
        error,
    };
}
