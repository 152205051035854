import { useIsAccessTokenExpired } from 'app/domains/auth/hooks/useIsAccessTokenExpired';
import { useGetCurrentUser } from 'shared/domains/documents';
import { ASSISTANT_COMPONENT_NAME } from 'shared/domains/pleditor@next';
import { PleditorError } from '../../components/PleditorError';
import { PleditorLoader } from '../../components/PleditorLoader';
import { useGetUserIdAccess } from '../../hooks/useGetUsersAccess';
import { RTCPleditor } from '../RTCPleditor';
import { useImportDocumentToCcs } from '../hooks';
import { APP_COMPOSED_PLUGINS } from '../plugins';
export function AppComposedRTCPleditor() {
    const currentUser = useGetCurrentUser();
    const { isAccessTokenExpired } = useIsAccessTokenExpired();
    const { usersExternal, usersWithoutAccess, usersWithAccess } = useGetUserIdAccess();
    const { loading, error } = useImportDocumentToCcs();
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const toolbarItems = [
        '<->',
        'blockComment',
        '|',
        'commentsArchive',
        '|',
        'toggleComments',
        '|',
        'exportWord',
        'importWord',
    ];
    const balloonToolbarItems = ['blockComment', ASSISTANT_COMPONENT_NAME];
    return (<RTCPleditor editorName="AppComposedRTCPleditor" documentType="composed" toolbarItems={toolbarItems} balloonToolbarItems={balloonToolbarItems} currentUser={currentUser} usersExternal={usersExternal} usersWithAccess={usersWithAccess} usersWithoutAccess={usersWithoutAccess} clientPlugins={APP_COMPOSED_PLUGINS} isSessionExpired={isAccessTokenExpired} restricted/>);
}
