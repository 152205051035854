import { useState } from 'react';
import { FilterButton, Menu, MenuBody, MenuList } from '@pocketlaw/tetris';
import { MenuSingleSelectItem } from 'shared/domains/common-ui';
import { useFilter } from '../useFilter';
/**
 * Component to display a dropdown of filter operators.
 */
export function OperatorSelector(props) {
    const { name, label, operators, openOnNotSet = true } = props;
    const filter = useFilter(name);
    const initiallyOpen = openOnNotSet && !filter.operator;
    const [open, setOpen] = useState(initiallyOpen);
    const toggleMenu = () => setOpen(!open);
    const handleSelect = (operator) => {
        setOpen(false);
        operator.onSelect();
    };
    const isActive = (operator) => {
        const idArray = Array.isArray(operator.id) ? operator.id : [operator.id];
        return idArray.includes(filter.operator);
    };
    const selectedOperator = operators.find(operator => operator.id === filter.operator);
    return (<Menu open={open} onClose={toggleMenu} target={<FilterButton type="button" appearance={filter.isTouched ? 'draft' : 'default'} onClick={toggleMenu}>
          {label && <b>{label}</b>} {selectedOperator === null || selectedOperator === void 0 ? void 0 : selectedOperator.label}
        </FilterButton>}>
      <MenuBody>
        <MenuList>
          {operators.map(operator => (<MenuSingleSelectItem key={operator.id.toString()} selected={isActive(operator)} onClick={() => handleSelect(operator)}>
              {operator.menuItem}
            </MenuSingleSelectItem>))}
        </MenuList>
      </MenuBody>
    </Menu>);
}
