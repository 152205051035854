import { Loader, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const StyledLoader = styled(Loader) `
  transition: all 175ms ease-in-out;
`;
function transitionStates(state) {
    switch (state) {
        case 'hidden':
            return css `
        display: none;

        ${StyledLoader} {
          opacity: 0;
          transform: scale(0.75);
        }
      `;
        case 'entering':
        case 'leaving':
            return css `
        display: flex;

        ${StyledLoader} {
          opacity: 0;
          transform: scale(0.75);
        }
      `;
        default:
            return css `
        display: flex;

        ${StyledLoader} {
          opacity: 1;
          transform: scale(1);
        }
      `;
    }
}
export const Container = styled.div `
  display: flex;
  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.md')};

  ${props => transitionStates(props.$state)};
`;
