export function getMetricFilterPayload(bucket, aggregation) {
    const { name } = bucket;
    const { field } = aggregation.metricInput || {};
    /**
     * When aggregating on metrics. We will for example get back an average value.
     * This makes it so that we can not really filter on the value. But instead we
     * filter on the field being not null.
     */
    const filter = { [field]: { isNull: false } };
    return {
        id: name,
        field,
        filter,
    };
}
