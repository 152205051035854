import { Fragment } from 'react';
import { useField } from 'formik';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
import { DateHistogramInput } from './DateHistogramInput';
import { DateRangeInput } from './DateRangeInput';
import { MetricInput } from './MetricInput';
import { TermsInput } from './TermsInput';
import { TopHitsInput } from './TopHitsInput';
export function DataPoints(props) {
    const { name, isSubAggregation = false } = props;
    const [field, , helpers] = useField(name);
    const { value } = field;
    const handleRemoveDataPoint = () => {
        helpers.setValue([]);
    };
    const getProps = (index) => {
        var _a;
        return ({
            pathBase: `${field.name}[${index}]`,
            remove: handleRemoveDataPoint,
            isSubAggregation,
            key: (_a = field.value[index]) === null || _a === void 0 ? void 0 : _a.name,
        });
    };
    const getComponent = (type) => {
        switch (type) {
            case SearchAggregationType.Terms:
                return TermsInput;
            case SearchAggregationType.DateHistogram:
                return DateHistogramInput;
            case SearchAggregationType.DateRange:
                return DateRangeInput;
            case SearchAggregationType.Metric:
                return MetricInput;
            case SearchAggregationType.TopHits:
                return TopHitsInput;
            default:
                return Fragment;
        }
    };
    const dataPoints = value === null || value === void 0 ? void 0 : value.map((aggregation, index) => ({
        component: getComponent(aggregation === null || aggregation === void 0 ? void 0 : aggregation.type),
        componentProps: getProps(index),
    }));
    if (!(dataPoints === null || dataPoints === void 0 ? void 0 : dataPoints.length)) {
        return null;
    }
    return dataPoints.map(({ component: Component, componentProps }) => (<Component {...componentProps}/>));
}
