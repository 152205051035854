import { View } from 'shared/domains/pleditor@next';
import { PleditorPlugin } from '../PleditorPlugin';
export class FlexGap extends PleditorPlugin {
    static get pluginName() {
        return 'FlexGap';
    }
    init() {
        const { editor } = this;
        editor.ui.componentFactory.add('<->', () => {
            const view = new View();
            view.setTemplate({
                tag: 'div',
                attributes: { class: 'ck-flex-gap' },
            });
            return view;
        });
    }
}
