import { 
// Plugins
Alignment, Autosave, BalloonToolbar, Bold, CloudServices, EasyImage, Essentials, FontSize, FontColor, FontBackgroundColor, Heading, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload, Indent, IndentBlock, Italic, Link, List, AdjacentListsSupport, Notification, PageBreak, Paragraph, RestrictedEditingMode, Strikethrough, Table, TableToolbar, TableCaption, TableColumnResize, TableProperties, TableCellProperties, Underline, } from 'ckeditor5';
import { Comments, Permissions, TrackChanges, RevisionTracker, RevisionHistory, RealTimeCollaborativeEditing, RealTimeCollaborativeComments, RealTimeCollaborativeTrackChanges, RealTimeCollaborativeRevisionHistory, ImportWord, ExportWord, } from 'ckeditor5-premium-features';
import { Assistant } from './plugins/Assistant';
import { AttachmentStore, AttachmentWidgetEditing, InsertAttachmentWidgetEditing, } from './plugins/Attachments';
import { HighlightPlugin } from './plugins/Highlight';
import { ListAttributesExtender } from './plugins/ListAttributesExtender';
/**
 * A list of plugins that are available on the server. Clients that utilises Real Time Collaboration must include an unmodifed copy of this list.
 */
export const pluginList = [
    // Custom
    Assistant,
    HighlightPlugin,
    ListAttributesExtender,
    AttachmentStore,
    AttachmentWidgetEditing,
    InsertAttachmentWidgetEditing,
    // CKEditor
    Alignment,
    Autosave,
    BalloonToolbar,
    Bold,
    CloudServices,
    Comments,
    EasyImage,
    Essentials,
    ExportWord,
    FontSize,
    FontColor,
    FontBackgroundColor,
    Heading,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImportWord,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    AdjacentListsSupport,
    Notification,
    PageBreak,
    Paragraph,
    Permissions,
    RealTimeCollaborativeEditing,
    RealTimeCollaborativeComments,
    RealTimeCollaborativeRevisionHistory,
    RealTimeCollaborativeTrackChanges,
    RestrictedEditingMode,
    RevisionHistory,
    RevisionTracker,
    Strikethrough,
    Table,
    TableToolbar,
    TableCaption,
    TableColumnResize,
    TableProperties,
    TableCellProperties,
    TrackChanges,
    Underline,
];
export const editorConfig = {
    plugins: pluginList,
};
export { DecoupledEditor } from 'ckeditor5';
