import { t } from '@lingui/macro';
import { SidebarMenuCollapsible } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useCompanyFeatures } from 'app/domains/companies';
import { useRole } from 'app/domains/users';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleSettings() {
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const userRole = useRole();
    const { isFeatureAvailable } = useCompanyFeatures();
    const useApiManagement = isFeatureAvailable('web_api');
    const activeChild = useLinkMatch({
        matchPath: ['/users-teams', '/settings'],
        matchExact: false,
    });
    return (<SidebarMenuCollapsible icon={<Falcon icon="cog"/>} label={t({ comment: 'Sidebar menu - Settings label', message: 'Settings' })} activeChild={activeChild} collapsibleKey="settings" open={collapsibleStates.settings} onToggle={toggleCollapsible}>
      <CollapsibleItemLink to="/users-teams" label={t({ comment: 'Sidebar menu - Users & teams label', message: 'Users & teams' })} matchPath={['/users-teams']}/>
      {userRole.can('manage:settings') && (<CollapsibleItemLink to="/settings/profile" label={t({ comment: 'Sidebar menu - Account label', message: 'Account' })} matchPath={['/settings/profile', '/settings/plan', '/settings/esigning']}/>)}
      {useApiManagement && (<CollapsibleItemLink to="/settings/api" label={t({ comment: 'Sidebar menu - API Keys', message: 'API keys' })} matchPath={['/settings/api']}/>)}
    </SidebarMenuCollapsible>);
}
