import { useCallback, useState } from 'react';
import { FileBrowserProvider, ROOT } from 'app/domains/filesystem';
import { TemplateBrowserProvider } from 'app/domains/templates';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { FileDialog } from './FileDialog';
import { FileTemplateDialog } from './FileTemplateDialog';
export function PickFileTemplateDialog(props) {
    const { selfId, initialFolderId = ROOT, includeDocumentAi = false, customSelectable, onSubmitDocument, onSubmitTemplate, } = props;
    const [selectedDocument, setSelectedDocument] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const showTemplates = onSubmitTemplate !== undefined;
    const unsetSelectedDocument = useCallback(() => setSelectedDocument(undefined), []);
    const submitDocument = () => {
        if (selectedDocument) {
            onSubmitDocument(selectedDocument);
        }
    };
    const submitTemplate = () => {
        if (selectedTemplate && onSubmitTemplate) {
            onSubmitTemplate(selectedTemplate);
        }
    };
    const selectable = (entry) => entry.kind === EntryKind.Document;
    const fileBrowserProviderProps = {
        customLoadingState: true,
        showSignRequestStatus: true,
        parentId: initialFolderId,
        onSelect: setSelectedDocument,
        onClearSelection: unsetSelectedDocument,
        selectable: customSelectable || selectable,
        includeDocumentAi,
        selfId,
    };
    if (showTemplates) {
        return (<FileBrowserProvider {...fileBrowserProviderProps}>
        <TemplateBrowserProvider onSelect={setSelectedTemplate}>
          <FileTemplateDialog {...props} selectedEntryId={selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument.id} selectedTemplateId={selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id} onSubmitDocument={submitDocument} onSubmitTemplate={submitTemplate}/>
        </TemplateBrowserProvider>
      </FileBrowserProvider>);
    }
    return (<FileBrowserProvider {...fileBrowserProviderProps}>
      <FileDialog {...props} selectedEntryId={selectedDocument === null || selectedDocument === void 0 ? void 0 : selectedDocument.id} onSubmit={submitDocument}/>
    </FileBrowserProvider>);
}
