import { Plugin, Widget, toWidget } from 'ckeditor5';
import { InsertAttachmentWidgetCommand } from './command';
import { AttachmentStore } from '../attachmentStore';
export const ATTACHMENT_CONSTANTS = {
    // Class names
    className: 'document-attachment',
    classNameReactWrapper: 'document-attachment__react-wrapper',
    // The model element name
    modelElement: 'attachmentPreview',
    // The view element name
    viewElement: 'section',
    // The react component wrapper name
    reactWrapperElement: 'div',
};
export class AttachmentWidgetEditing extends Plugin {
    static get pluginName() {
        return 'AttachmentWidgetEditing';
    }
    static get requires() {
        return [Widget, AttachmentStore];
    }
    constructor(editor) {
        super(editor);
        this.store = editor.plugins.get(AttachmentStore);
        this.config = editor.config.get('attachments');
    }
    init() {
        this.defineSchema();
        this.defineConverters();
        this.editor.commands.add(InsertAttachmentWidgetCommand.pluginName, new InsertAttachmentWidgetCommand(this.editor));
    }
    defineSchema() {
        const { schema } = this.editor.model;
        schema.register(ATTACHMENT_CONSTANTS.modelElement, {
            // Behaves like a self-contained object (e.g. an image).
            isObject: true,
            // Allow in places where other blocks are allowed (e.g. directly in the root).
            allowWhere: '$block',
            // Each product preview has an ID. A unique ID tells the application which
            // product it represents and makes it possible to render it inside a widget.
            allowAttributes: ['id', 'oembedUrl'],
        });
    }
    defineConverters() {
        const { editor, store, config } = this;
        const { conversion } = editor;
        // <attachmentPreview> converters ((data) view → model)
        conversion.for('upcast').elementToElement({
            view: {
                name: ATTACHMENT_CONSTANTS.viewElement,
                classes: ATTACHMENT_CONSTANTS.className,
            },
            model: (viewElement, { writer: modelWriter }) => 
            // Read the "data-id" attribute from the view and set it as the "id" in the model.
            modelWriter.createElement(ATTACHMENT_CONSTANTS.modelElement, {
                id: viewElement.getAttribute('data-id'),
                oembedUrl: viewElement.getAttribute('data-oembed-url'),
            }),
        });
        // <attachmentPreview> converters (model → data view)
        conversion.for('dataDowncast').elementToElement({
            model: ATTACHMENT_CONSTANTS.modelElement,
            view: (modelElement, { writer: viewWriter }) => {
                const id = modelElement.getAttribute('id');
                const oembedUrl = modelElement.getAttribute('oembedUrl');
                const container = viewWriter.createContainerElement(ATTACHMENT_CONSTANTS.viewElement, {
                    class: ATTACHMENT_CONSTANTS.className,
                    'data-id': id,
                    'data-oembed-url': oembedUrl,
                });
                const content = viewWriter.createEmptyElement('oembed', { url: oembedUrl });
                viewWriter.insert(viewWriter.createPositionAt(container, 0), content);
                return container;
            },
        });
        // <attachmentPreview> converters (model → editing view)
        conversion.for('editingDowncast').elementToElement({
            model: ATTACHMENT_CONSTANTS.modelElement,
            view: (modelElement, { writer: viewWriter }) => {
                // In the editing view, the model <attachmentPreview> corresponds to:
                //
                // <section class="document-attachment" data-id="...">
                //     <div class="document-attachment__react-wrapper">
                //         <ProductPreview /> (React component)
                //     </div>
                // </section>
                const id = modelElement.getAttribute('id');
                const oembedUrl = modelElement.getAttribute('oembedUrl');
                const attachment = store.get(id);
                // The outermost <section class="document-attachment" data-id="..."></section> element.
                const section = viewWriter.createContainerElement(ATTACHMENT_CONSTANTS.viewElement, {
                    class: ATTACHMENT_CONSTANTS.className,
                    'data-id': id,
                    'data-oembed-url': oembedUrl,
                });
                if (!config) {
                    return toWidget(section, viewWriter);
                }
                // The inner <div class="document-attachment__react-wrapper"></div> element.
                // This element will host a React <AttachmentWidget /> component.
                const reactWrapper = viewWriter.createRawElement(ATTACHMENT_CONSTANTS.reactWrapperElement, {
                    class: ATTACHMENT_CONSTANTS.classNameReactWrapper,
                }, domElement => {
                    config.renderAttachment({ attachment, modelElement }, domElement);
                });
                viewWriter.insert(viewWriter.createPositionAt(section, 0), reactWrapper);
                return toWidget(section, viewWriter, {
                    label: 'attachment preview widget',
                    hasSelectionHandle: true,
                });
            },
        });
        conversion.for('downcast').add(downcastDispatcher => {
            downcastDispatcher.on('remove:attachmentPreview', () => {
                store.setEmbeds();
            });
        });
    }
}
