var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { getDocument, PDFWorker } from 'pdfjs-dist';
import * as Sentry from 'shared/domains/sentry';
import { useDocumentPDF } from '../useDocumentPDF';
const PASSWORD_EXCEPTION = 'PasswordException';
const workerInstance = new Worker(new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url));
function isAbortError(error) {
    return error instanceof DOMException && error.name === 'AbortError';
}
export const usePDF = () => {
    var _a;
    const { pdf: pdfFile, loading: pdfLoading, error: pdfError } = useDocumentPDF();
    const url = (_a = pdfFile === null || pdfFile === void 0 ? void 0 : pdfFile.signed) === null || _a === void 0 ? void 0 : _a.url;
    const [pdf, setPDF] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [passwordProtected, setPasswordProtected] = useState(false);
    const [permissions, setPermissions] = useState(null);
    useEffect(() => {
        if (url && !pdf) {
            const controller = new AbortController();
            const worker = PDFWorker.fromPort({
                port: workerInstance,
            });
            fetch(url, {
                signal: controller.signal,
            })
                .then((response) => __awaiter(void 0, void 0, void 0, function* () {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const data = yield response.arrayBuffer();
                const isRequestCancelled = controller.signal.aborted;
                if (isRequestCancelled) {
                    return;
                }
                const document = yield getDocument({ data, worker }).promise.catch(e => {
                    if (!e) {
                        throw new Error('Unknown Error when loading PDF: getDocument() from "pdfjs-dist" returned "undefined" error');
                    }
                    else {
                        throw e;
                    }
                });
                const documentPermissions = yield document.getPermissions();
                setPDF(document);
                setPermissions(documentPermissions);
            }))
                .catch(e => {
                if (e.name === PASSWORD_EXCEPTION) {
                    setPasswordProtected(true);
                    setError(true);
                }
                else if (!isAbortError(e)) {
                    Sentry.captureException(e);
                    setError(true);
                }
            })
                .finally(() => setLoading(false));
            return () => {
                controller.abort();
                worker.destroy();
            };
        }
        return undefined;
    }, [url, pdf]);
    return {
        data: pdf,
        loading: loading || pdfLoading,
        error: error || Boolean(pdfError),
        passwordProtected,
        permissions,
    };
};
