export const InsightsTopHitsResultFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InsightsTopHitsResult' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SearchResponseTopHitsAggregation' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'SearchResultDocument' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentNoticePeriodMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentOriginalTermMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentRenewalTermMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentTotalContractCost' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentTotalContractValue' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentNoticePeriodDays' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const InsightsVisualizationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'InsightsVisualization' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArgsWhere' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'aggregations' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchArgsAggregations' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'search' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'query' },
                                value: { kind: 'StringValue', value: '', block: false },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '0' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'aggregations' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'aggregations' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referrer' },
                                value: { kind: 'StringValue', value: 'insights', block: false },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'totalHitsCount' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InsightsTopHitsResult' },
                                            },
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: { kind: 'Name', value: 'SearchResponseScalarAggregation' },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                    kind: 'NamedType',
                                                    name: { kind: 'Name', value: 'SearchResponseBucketedAggregation' },
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'sumOtherCount' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'buckets' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        alias: { kind: 'Name', value: 'id' },
                                                                        name: { kind: 'Name', value: 'key' },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        alias: { kind: 'Name', value: 'name' },
                                                                        name: { kind: 'Name', value: 'key' },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        alias: { kind: 'Name', value: 'value' },
                                                                        name: { kind: 'Name', value: 'count' },
                                                                    },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                                                    { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: { kind: 'Name', value: 'aggregations' },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: { kind: 'Name', value: '__typename' },
                                                                                },
                                                                                {
                                                                                    kind: 'FragmentSpread',
                                                                                    name: { kind: 'Name', value: 'InsightsTopHitsResult' },
                                                                                },
                                                                                {
                                                                                    kind: 'InlineFragment',
                                                                                    typeCondition: {
                                                                                        kind: 'NamedType',
                                                                                        name: {
                                                                                            kind: 'Name',
                                                                                            value: 'SearchResponseBucketedAggregation',
                                                                                        },
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'name' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'sumOtherCount' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'type' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'buckets' },
                                                                                                selectionSet: {
                                                                                                    kind: 'SelectionSet',
                                                                                                    selections: [
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            alias: { kind: 'Name', value: 'id' },
                                                                                                            name: { kind: 'Name', value: 'key' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            alias: { kind: 'Name', value: 'name' },
                                                                                                            name: { kind: 'Name', value: 'key' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            alias: { kind: 'Name', value: 'value' },
                                                                                                            name: { kind: 'Name', value: 'count' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'from' },
                                                                                                        },
                                                                                                        {
                                                                                                            kind: 'Field',
                                                                                                            name: { kind: 'Name', value: 'to' },
                                                                                                        },
                                                                                                    ],
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'InlineFragment',
                                                                                    typeCondition: {
                                                                                        kind: 'NamedType',
                                                                                        name: {
                                                                                            kind: 'Name',
                                                                                            value: 'SearchResponseScalarAggregation',
                                                                                        },
                                                                                    },
                                                                                    selectionSet: {
                                                                                        kind: 'SelectionSet',
                                                                                        selections: [
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'type' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'name' },
                                                                                            },
                                                                                            {
                                                                                                kind: 'Field',
                                                                                                name: { kind: 'Name', value: 'value' },
                                                                                            },
                                                                                        ],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InsightsTopHitsResult' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SearchResponseTopHitsAggregation' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'results' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'entityId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'entityType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                        kind: 'NamedType',
                                        name: { kind: 'Name', value: 'SearchResultDocument' },
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentNoticePeriodMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentOriginalTermMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentRenewalTermMonths' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentTotalContractCost' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentTotalContractValue' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'metadataDocumentNoticePeriodDays' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
