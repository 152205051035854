import { useFileBrowserContext } from 'app/domains/filesystem';
import { PickerBreadcrumbs } from '../PickerBreadcrumbs';
import { PickerDialog } from '../PickerDialog';
import { PickerFooter } from '../PickerFooter';
import { PickerHeader } from '../PickerHeader';
import { PickerLoaderOverlay } from '../PickerLoaderOverlay';
import { usePickerContext } from '../PickerProvider';
import { PickerTableFilesystem } from '../PickerTableFilesystem';
import { PickerPermissionWarning } from '../PickerWarnings/PickerPermissionWarning';
import { PickerRootWarning } from '../PickerWarnings/PickerRootWarning';
export function FolderDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, selectedEntryAlert, loaderOverlay, onClose } = props;
    const { submit, showRootWarning, hideRootWarning } = usePickerContext();
    const { fileBrowserSearch } = useFileBrowserContext();
    const { search, searchQuery, clearSearch } = fileBrowserSearch;
    const handleSearch = (query) => {
        search(query, true);
    };
    return (<PickerDialog>
      <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose} search={handleSearch} searchQuery={searchQuery} clearSearch={clearSearch}/>
      <PickerBreadcrumbs />
      <PickerTableFilesystem selectType="folder" selectedEntryAlert={selectedEntryAlert}/>
      <PickerFooter showPagination showCreateFolder submitLabel={submitLabel} onSubmit={submit}/>
      <PickerRootWarning show={showRootWarning} onClose={hideRootWarning} onSubmit={submit}/>
      <PickerPermissionWarning show={showRootWarning} onClose={hideRootWarning}/>
      {loaderOverlay && <PickerLoaderOverlay {...loaderOverlay}/>}
    </PickerDialog>);
}
