export function getNextAggregations(aggregations) {
    const [aggregation] = aggregations || [];
    const { dateHistogramInput, dateRangeInput, termsInput } = aggregation || {};
    const { aggregations: inputAggregations } = dateHistogramInput || dateRangeInput || termsInput || {};
    const hasSubAggregations = inputAggregations && Boolean(Object.values(inputAggregations).length > 0);
    if (hasSubAggregations) {
        return inputAggregations;
    }
    return aggregations || [];
}
