import { Plugin, RestrictedEditingModeEditing, scrollViewportToShowTarget, } from 'ckeditor5';
import { HighlightAtCommand } from './commands/highlightAt';
import { HighlightResetCommand } from './commands/reset';
import { HighlightState } from './state';
export class HighlightPlugin extends Plugin {
    static get pluginName() {
        return 'HighlightMarkerPlugin';
    }
    constructor(editor) {
        super(editor);
        this.state = new HighlightState();
    }
    init() {
        this.defineConverters();
        this.defineCommands();
        const { editor, state } = this;
        const scrollToHighlightedAnchor = (_, __, value) => {
            if (value) {
                const { domConverter } = editor.editing.view;
                const viewRange = editor.editing.mapper.toViewRange(value.marker.getRange());
                const targetElement = domConverter.viewRangeToDom(viewRange);
                scrollViewportToShowTarget({ target: targetElement, alignToTop: true, ancestorOffset: 40 });
            }
        };
        this.listenTo(state, 'change:anchor', scrollToHighlightedAnchor);
    }
    afterInit() {
        const { editor } = this;
        // Enable commands in restricted editing mode
        if (editor.plugins.has(RestrictedEditingModeEditing.pluginName)) {
            const restrictedEditingPlugin = editor.plugins.get(RestrictedEditingModeEditing.pluginName);
            restrictedEditingPlugin.enableCommand(HighlightAtCommand.pluginName);
            restrictedEditingPlugin.enableCommand(HighlightResetCommand.pluginName);
        }
    }
    defineConverters() {
        const { editor } = this;
        editor.conversion.for('editingDowncast').markerToHighlight({
            model: 'highlightMarker',
            view: ({ markerName }) => {
                const [, id] = markerName.split(':');
                if (!id) {
                    return null;
                }
                // Marker removal from the view has a bug: https://github.com/ckeditor/ckeditor5/issues/7499
                // A minimal option is to return a new object for each converted marker...
                return {
                    name: 'span',
                    classes: ['pl-highlight--assistant'],
                    attributes: {
                        // ...however, adding a unique attribute should be future-proof..
                        'data-highlight-id': id,
                    },
                };
            },
        });
    }
    defineCommands() {
        const { editor, state } = this;
        editor.commands.add(HighlightAtCommand.pluginName, new HighlightAtCommand(editor, state));
        editor.commands.add(HighlightResetCommand.pluginName, new HighlightResetCommand(editor, state));
    }
}
