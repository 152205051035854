import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, Button, Dialog, DialogContent, DialogFooter, DialogHeader, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Markdown } from './Markdown';
export function PlaybookReferenceDialog(props) {
    var _a;
    const { onClose, feedback } = props;
    const { entries, ruleName } = (_a = feedback.feedback.playbookRule) !== null && _a !== void 0 ? _a : {
        ruleName: null,
        entries: [],
    };
    const ruleContent = entries
        .map(entry => {
        const { details, type } = entry;
        return `## ${type}\n\n${details === '' ? 'N/A' : details}`;
    })
        .join('\n\n');
    const title = ruleName ||
        t({
            message: 'Playbook reference',
            comment: 'Plai Review - Issue Item - Unknown Playbook Reference - dialog title',
        });
    const content = ruleContent ||
        t({
            message: 'Unknown playbook rule.',
            comment: 'Plai Review - Issue Item - Unknown Playbook Reference - dialog content',
        });
    return (<Dialog>
      <DialogHeader media={<AvatarWithLabel truncate title={title} shape="circle" appearance="light">
            <Falcon icon="book"/>
          </AvatarWithLabel>}/>
      <DialogContent>
        <Markdown contents={content}/>
      </DialogContent>
      <DialogFooter>
        <Button onClick={onClose}>
          <Trans comment="Plai Review - Issue Item - Playbook Reference - dialog button label">
            Got it
          </Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
