import { pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import { motion } from 'motion/react';
import styled, { css, keyframes } from 'styled-components';
const textColor = css `rgb(228 224 255)`;
const textColorFocus = css `rgb(255 255 255)`;
export const InsightsChatContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.lg')};

  background: rgb(46, 8, 41);
  background: linear-gradient(
    90deg,
    rgba(46, 8, 41, 1) 0%,
    rgba(4, 3, 1, 1) 18%,
    rgba(35, 8, 78, 1) 35%,
    rgba(4, 3, 1, 1) 80%,
    rgba(31, 15, 62, 1) 100%
  );
`;
export const InputContainer = styled.div `
  display: flex;
  width: 100%;
  align-items: center;

  background-color: transparent;
  border-radius: ${themeGet('borderRadius.lg')};
  border-color: transparent;

  gap: ${themeGet('spacing.md')};

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: ${textColor};

    font-size: ${themeGet('fontSize.medium')};

    transition: all 0.2s ease-in-out;

    align-self: stretch;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${textColor};
      font-weight: ${themeGet('fontWeight.semibold')};
    }
  }

  [data-icon='falcon'] {
    color: ${textColor};
    transition: all 0.2s ease-in-out;
  }

  &:focus,
  &:focus-within {
    outline: none;

    [data-icon='falcon'] {
      color: ${textColorFocus};
    }

    input {
      color: ${textColorFocus};

      &::placeholder {
        color: ${textColorFocus};
      }
    }
  }
`;
export const PromptList = styled(motion.div).attrs(p => ({
    animate: {
        height: p.$open ? 'auto' : 0,
        marginTop: p.$open ? themeGet('spacing.xl') : 0,
        opacity: p.$open ? 1 : 0,
    },
    initial: false,
})) `
  display: flex;
  gap: ${themeGet('spacing.lg')};
  flex-wrap: wrap;
`;
const spin = keyframes `
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;
export const ButtonLoader = styled.div `
  position: relative;
  display: inline;

  &:after {
    display: ${props => (props.$loading ? 'block' : 'none')};
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    animation: ${spin} 1s linear infinite;

    border: ${pxToRem(3)} solid transparent;

    border-bottom-color: ${themeGetColor('gray.0')};

    transition: all 0.2s ease-in-out;

    border-radius: 50%;

    transition: all 0.2s ease-in-out;
  }
`;
