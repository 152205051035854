import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { differenceInCalendarDays } from 'date-fns';
import { localizedFormatDate } from 'shared/domains/common-utils';
export function LatestAttemptFailed(props) {
    const { latestAttemptTimestamp } = props;
    if (!latestAttemptTimestamp) {
        return null;
    }
    const dayDiff = differenceInCalendarDays(new Date(), new Date(latestAttemptTimestamp));
    const date = localizedFormatDate(latestAttemptTimestamp, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const title = t({
        comment: 'The latest esigning attempt failed - Alert title',
        message: 'The latest eSigning attempt failed',
    });
    const lessThanADayMessage = t({
        comment: 'The latest esigning attempt failed - Less than a day ago - Alert message',
        message: `The latest attempt failed. Please try again.`,
    });
    const moreThanADayMessage = t({
        comment: 'The latest esigning attempt failed - More than a day ago - Alert message',
        message: `The latest attempt failed ${dayDiff} days ago (${date}). Please try again.`,
    });
    const message = dayDiff < 1 ? lessThanADayMessage : moreThanADayMessage;
    return <Alert appearance="info" title={title} message={message}/>;
}
