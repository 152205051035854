/* eslint-disable import/no-extraneous-dependencies */
import { Plugin, } from 'ckeditor5';
export class ListAttributesExtender extends Plugin {
    constructor() {
        super(...arguments);
        this.schemaItems = ['heading1', 'heading2', 'heading3', 'paragraph'];
        this.modelAttributeKey = 'listStart';
        this.viewAttributeKey = 'start';
        this.setupSchema = () => {
            const { editor, schemaItems, modelAttributeKey } = this;
            schemaItems.forEach(item => {
                editor.model.schema.extend(item, {
                    allowAttributes: [modelAttributeKey],
                });
            });
        };
        this.setupDowncast = () => {
            const { editor, findParent, findMappedViewElement, modelAttributeKey, viewAttributeKey } = this;
            editor.conversion.for('downcast').add(dispatcher => dispatcher.on(`attribute:${modelAttributeKey}`, (_evt, data, conversionApi) => {
                const { item: modelElement } = data;
                const { mapper, writer } = conversionApi;
                if (!modelElement.is('element') || !data.item.hasAttribute(modelAttributeKey)) {
                    return;
                }
                const start = data.item.getAttribute(modelAttributeKey);
                if (typeof start !== 'string') {
                    return;
                }
                const viewElement = findMappedViewElement(modelElement, mapper, editor.model);
                if (!viewElement) {
                    return;
                }
                const olParent = findParent(viewElement, 'ol');
                if (!olParent) {
                    return;
                }
                writer.setAttribute(viewAttributeKey, start, olParent);
            }, { priority: 'low' }));
        };
        this.setupUpcast = () => {
            const { editor, findModelChildText, findViewChildText, modelAttributeKey } = this;
            editor.conversion.for('upcast').add(dispatcher => {
                dispatcher.on('element', (_evt, data, conversionApi) => {
                    const { viewItem, modelRange } = data;
                    const start = viewItem.getAttribute('start');
                    if (!viewItem.is('element') || !start || !modelRange) {
                        return;
                    }
                    for (const item of modelRange.getItems({ shallow: true })) {
                        const modelText = findModelChildText(item);
                        const viewText = findViewChildText(viewItem);
                        if (modelText !== viewText) {
                            return;
                        }
                        conversionApi.writer.setAttribute(modelAttributeKey, start, item);
                    }
                }, { priority: 'lowest' });
            });
        };
        this.findViewChildText = (element) => {
            if (!element.is('element')) {
                return '';
            }
            const [child] = [...element.getChildren()];
            if (!child) {
                return '';
            }
            if (child.is('$text')) {
                return child.data;
            }
            if (!child.is('element')) {
                return '';
            }
            return this.findViewChildText(child);
        };
        this.findModelChildText = (element, findFromParent = true) => {
            if (element === null || !element.is('element')) {
                return '';
            }
            const [child] = [...element.getChildren()];
            if (!child) {
                if (findFromParent && element.parent && element.index) {
                    return this.findModelChildText(element.parent.getChild(element.index + 1), false);
                }
                return '';
            }
            if (child.is('$text')) {
                return child.data;
            }
            return this.findModelChildText(child);
        };
        // eslint-disable-next-line class-methods-use-this
        this.findMappedViewElement = (element, mapper, model) => {
            const modelRange = model.createRangeOn(element);
            const viewRange = mapper.toViewRange(modelRange).getTrimmed();
            return viewRange.end.nodeBefore;
        };
        this.findParent = (element, name) => {
            const { parent } = element;
            if (!parent) {
                return null;
            }
            if (!parent.is('element')) {
                return null;
            }
            if (parent.name === name) {
                return parent;
            }
            return this.findParent(parent, name);
        };
    }
    static get pluginName() {
        return 'ListAttributesExtender';
    }
    afterInit() {
        this.setupSchema();
        this.setupDowncast();
        this.setupUpcast();
    }
}
