import { ObservableMixin } from 'ckeditor5';
export class AssistantState extends ObservableMixin() {
    constructor() {
        super();
        this.set('marker', null);
    }
    clear(model) {
        const { marker } = this;
        if (marker) {
            this.set('marker', null);
            model.change(writer => {
                writer.removeMarker(marker);
            });
        }
    }
}
