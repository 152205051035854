import { AppApiConnector } from '../plugins/AppApiConnector';
import { InsertAttachmentWidgetUI } from '../plugins/Attachments';
import { BlockComment } from '../plugins/BlockComment';
import { CommentsUi } from '../plugins/CommentsUi';
import { DisableTableDragDrop } from '../plugins/DisableTableDragDrop';
import { EditMode } from '../plugins/EditMode';
import { ExternalApiConnector } from '../plugins/ExternalApiConnector';
import { ExternalSuggestions } from '../plugins/ExternalSuggestions';
import { FlexGap } from '../plugins/FlexGap';
import { HandleDocumentFlushedPlugin } from '../plugins/HandleDocumentFlushedPlugin';
import { ImportWordRTC } from '../plugins/ImportWordRTC';
import { InlineComment } from '../plugins/InlineComment';
import { OffsetPlugin } from '../plugins/OffsetPlugin';
import { ReviewPlugin } from '../plugins/Review';
import { RevisionHistoryToolbarHelper } from '../plugins/RevisionHistoryToolbarHelper';
import { SessionUsers } from '../plugins/SessionUsers';
import { ToastHandler } from '../plugins/ToastHandler';
import { ToggleComments } from '../plugins/ToggleComments';
import { TopToolbar } from '../plugins/TopToolbar';
import { UserMentionsRTC } from '../plugins/UserMentionsRTC';
import { UsersManagerRTC } from '../plugins/UsersManagerRTC';
const BASE_PLUGINS = [
    TopToolbar,
    FlexGap,
    CommentsUi,
    OffsetPlugin,
    ToastHandler,
    ToggleComments,
    SessionUsers,
    HandleDocumentFlushedPlugin,
    UserMentionsRTC,
    UsersManagerRTC,
    InsertAttachmentWidgetUI,
];
// Composed
const BASE_COMPOSED_PLUGINS = [...BASE_PLUGINS, BlockComment, DisableTableDragDrop];
export const APP_COMPOSED_PLUGINS = [AppApiConnector, ...BASE_COMPOSED_PLUGINS, ReviewPlugin];
export const EXTERNAL_COMPOSED_PLUGINS = [ExternalApiConnector, ...BASE_COMPOSED_PLUGINS];
// Free Text
const BASE_FREE_TEXT_PLUGINS = [...BASE_PLUGINS, InlineComment];
export const APP_FREE_TEXT_PLUGINS = [
    AppApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    EditMode,
    ImportWordRTC,
    RevisionHistoryToolbarHelper,
    ReviewPlugin,
];
export const EXTERNAL_FREE_TEXT_PLUGINS = [
    ExternalApiConnector,
    ...BASE_FREE_TEXT_PLUGINS,
    ExternalSuggestions,
];
