import { constructSearchURL } from 'app/domains/global-search';
import { TableVisualization } from './TableVisualization';
import { Unsupported } from '../Unsupported';
import { bucketAggregationToFilter } from '../bucketAggregationToFilter';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { bucketTypeGuard } from '../typeGuards';
export function BucketTable(props) {
    const { data, aggregations, filters } = props;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations;
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const field = getSelectedField(aggregation);
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    const entries = dataPoint.buckets.map(bucket => (Object.assign(Object.assign({}, bucket), { name: fieldDisplayValue(field, bucket.name), link: constructSearchURL({
            where: Object.assign(Object.assign({}, filters), bucketAggregationToFilter(bucket, aggregation)),
        }) })));
    return <TableVisualization entries={entries}/>;
}
