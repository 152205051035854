import { useState } from 'react';
import { t } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { InsightsChatInput } from './InsightsChatInput';
import { InsightsChatContainer, PromptList } from './styled';
export function GenerateWidget(props) {
    const { query, error, loading, onChange, onSubmit, handleSelectPrompt } = props;
    const [focus, setFocus] = useState(false);
    const prompts = [
        {
            title: t({ message: 'Renewal alert', comment: 'Insights - Chat prompt title' }),
            message: 'A bar chart showing signed contracts with upcoming notice dates the next 30, 60 and 90 days. Only contracts with automatic renewal',
        },
        {
            title: t({
                message: 'Indemnity & uncapped liability',
                comment: 'Insights - Chat prompt title',
            }),
            message: 'Signed contracts with commercial document category with a indemnity clause where limitation of liability scope is uncapped',
        },
        {
            title: t({ message: 'Signed commercial contracts', comment: 'Insights - Chat prompt title' }),
            message: 'Line chart with commercial documents signed within a year',
        },
        {
            title: t({ message: 'Fee adjustment options', comment: 'Insights - Chat prompt title' }),
            message: 'All contracts with an adjustable fee clause. Breakdown by document type',
        },
        {
            title: t({ message: 'Expiring contracts', comment: 'Insights - Chat prompt title' }),
            message: 'In a bar chart, all contracts with termination date within the next 90 days (break down by 30/60/90 days)',
        },
    ];
    return (<InsightsChatContainer onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
      <InsightsChatInput value={query} onChange={onChange} onSubmit={onSubmit} disabled={loading} error={error} loading={loading}/>

      <PromptList $open={focus}>
        {prompts.map(prompt => (<Button key={prompt.title} type="button" appearance="glass" size="xs" onClick={() => handleSelectPrompt(prompt.message)}>
            {prompt.title}
          </Button>))}
      </PromptList>
    </InsightsChatContainer>);
}
