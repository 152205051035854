var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { GetDocumentNameDocument } from './query.gql';
export function useDocumentName(id) {
    var _a;
    const _b = useQuery(GetDocumentNameDocument, {
        variables: { id },
    }), { data } = _b, rest = __rest(_b, ["data"]);
    const documentName = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.name) || '';
    return Object.assign({ documentName }, rest);
}
