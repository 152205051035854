import { scrollbar, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
export const ChatContainer = styled.div `
  ${scrollbar()}

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: ${themeGet('spacing.xl')};
  padding-bottom: 0;
  margin-right: ${themeGet('spacing.sm')};

  & * {
    overflow-anchor: none;
  }
`;
