import { Plugin, Widget, toWidget } from 'ckeditor5';
import { Users } from 'ckeditor5-premium-features';
import { InsertSelectAttachmentWidgetCommand } from './command';
import { AttachmentStore } from '../attachmentStore';
import { InsertAttachmentWidgetCommand } from '../attachmentWidget/command';
export const SELECT_ATTACHMENT_CONSTANTS = {
    // Class names
    className: 'select-document-attachment',
    classNameReactWrapper: 'select-document-attachment__react-wrapper',
    // The model element name
    modelElement: 'selectAttachmentPreview',
    modelAttribute: 'userId',
    // The view element name
    viewElement: 'section',
    viewAttribute: 'data-user-id',
    // The react component wrapper name
    reactWrapperElement: 'div',
};
export class InsertAttachmentWidgetEditing extends Plugin {
    static get pluginName() {
        return 'InsertAttachmentWidgetEditing';
    }
    static get requires() {
        return [Widget, AttachmentStore, Users];
    }
    constructor(editor) {
        super(editor);
        this.onSelectAttachment = (attachment) => {
            this.editor.execute(InsertAttachmentWidgetCommand.pluginName, attachment);
            this.store.addEmbed(attachment.id);
        };
        this.onCloseDropdown = (modelElement) => {
            this.removeWidget(modelElement);
        };
        this.removeWidget = (modelElement) => {
            const { editor } = this;
            editor.model.enqueueChange({ isLocal: false, isUndoable: false }, writer => {
                const position = writer.createPositionBefore(modelElement);
                writer.remove(modelElement);
                writer.insertElement('paragraph', position);
            });
        };
        this.store = editor.plugins.get(AttachmentStore);
        this.config = editor.config.get('attachments');
    }
    init() {
        this.defineSchema();
        this.defineConverters();
        this.editor.commands.add(InsertSelectAttachmentWidgetCommand.pluginName, new InsertSelectAttachmentWidgetCommand(this.editor));
    }
    defineSchema() {
        const { schema } = this.editor.model;
        schema.register(SELECT_ATTACHMENT_CONSTANTS.modelElement, {
            isObject: true,
            allowWhere: '$block',
            allowAttributes: [SELECT_ATTACHMENT_CONSTANTS.modelAttribute],
        });
    }
    defineConverters() {
        const { editor, config, onCloseDropdown, onSelectAttachment } = this;
        const { conversion } = editor;
        conversion.for('upcast').elementToElement({
            view: {
                name: SELECT_ATTACHMENT_CONSTANTS.viewElement,
                classes: SELECT_ATTACHMENT_CONSTANTS.className,
            },
            model: (viewElement, { writer: modelWriter }) => modelWriter.createElement(SELECT_ATTACHMENT_CONSTANTS.modelElement, {
                [SELECT_ATTACHMENT_CONSTANTS.modelAttribute]: viewElement.getAttribute(SELECT_ATTACHMENT_CONSTANTS.viewAttribute),
            }),
        });
        conversion.for('dataDowncast').elementToElement({
            model: SELECT_ATTACHMENT_CONSTANTS.modelElement,
            view: (_, { writer: viewWriter }) => viewWriter.createEmptyElement(SELECT_ATTACHMENT_CONSTANTS.viewElement, {
                class: SELECT_ATTACHMENT_CONSTANTS.className,
            }),
        });
        conversion.for('editingDowncast').elementToElement({
            converterPriority: 'lowest',
            model: SELECT_ATTACHMENT_CONSTANTS.modelElement,
            view: (modelElement, { writer: viewWriter }) => {
                const { attachments, isEmbedded } = editor.plugins.get(AttachmentStore);
                const { me: currentUser } = editor.plugins.get(Users);
                const userId = modelElement.getAttribute(SELECT_ATTACHMENT_CONSTANTS.modelAttribute);
                const section = viewWriter.createContainerElement(SELECT_ATTACHMENT_CONSTANTS.viewElement, {
                    class: SELECT_ATTACHMENT_CONSTANTS.className,
                    [SELECT_ATTACHMENT_CONSTANTS.viewAttribute]: userId,
                });
                if (!config) {
                    return toWidget(section, viewWriter);
                }
                const reactWrapper = viewWriter.createRawElement(SELECT_ATTACHMENT_CONSTANTS.reactWrapperElement, {
                    class: SELECT_ATTACHMENT_CONSTANTS.classNameReactWrapper,
                }, domElement => {
                    config.renderSelectAttachment({
                        hideMenu: userId !== (currentUser === null || currentUser === void 0 ? void 0 : currentUser.id),
                        attachments,
                        modelElement,
                        onClose: onCloseDropdown,
                        onSelect: onSelectAttachment,
                        isEmbedded,
                    }, domElement);
                });
                viewWriter.insert(viewWriter.createPositionAt(section, 0), reactWrapper);
                return toWidget(section, viewWriter, { label: 'select attachment preview widget' });
            },
        });
    }
}
