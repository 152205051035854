import { Command } from 'ckeditor5';
import { SELECT_ATTACHMENT_CONSTANTS } from './editing';
import { ATTACHMENT_CONSTANTS } from '../attachmentWidget/editing';
export class InsertSelectAttachmentWidgetCommand extends Command {
    static get pluginName() {
        return 'insertSelectAttachment';
    }
    execute(userId) {
        const { editor } = this;
        editor.model.enqueueChange({ isLocal: false, isUndoable: false }, writer => {
            const element = writer.createElement(SELECT_ATTACHMENT_CONSTANTS.modelElement, {
                [SELECT_ATTACHMENT_CONSTANTS.modelAttribute]: userId,
            });
            editor.model.insertObject(element);
        });
    }
    refresh() {
        const { model } = this.editor;
        const { selection } = model.document;
        const position = selection.getFirstPosition();
        if (!position) {
            this.isEnabled = false;
            return;
        }
        if (this.isAttachmentSelected(selection)) {
            this.isEnabled = false;
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, SELECT_ATTACHMENT_CONSTANTS.modelElement);
        this.isEnabled = allowedIn !== null;
    }
    // eslint-disable-next-line class-methods-use-this
    isAttachmentSelected(selection) {
        const ranges = selection.getRanges();
        for (const range of ranges) {
            if (!range.isCollapsed) {
                for (const item of range.getItems()) {
                    if (item.is('element', ATTACHMENT_CONSTANTS.modelElement) ||
                        item.is('element', SELECT_ATTACHMENT_CONSTANTS.modelElement)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
