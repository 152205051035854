import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function RestrictedPermissionsPdfAlert() {
    return (<Alert appearance="danger" title={t({
            comment: 'eSigning restricted permissions pdf - Alert title for when the pdf has restricted permissions',
            message: 'The PDF has restricted permissions',
        })} message={t({
            comment: 'eSigning restricted permissions pdf - Alert message for when the pdf has restricted permissions',
            message: 'Please ensure that no editing, extracting or printing restrictions has been added to this file',
        })}/>);
}
