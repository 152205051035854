import { t } from '@lingui/macro';
import { v4 as uuid } from 'uuid';
import { ButtonView, Plugin } from 'shared/domains/pleditor@next';
import { Comment } from './icons';
export class BlockComment extends Plugin {
    static get pluginName() {
        return 'BlockComment';
    }
    init() {
        const { editor } = this;
        const commentsRepository = this.editor.plugins.get('CommentsRepository');
        const createToolbarButton = () => {
            const button = new ButtonView();
            button.set({
                withText: true,
                label: t({ comment: 'Add block comment button text', message: 'Comment' }),
                icon: Comment,
                class: 'pleditor-toolbar-fa-icon-button',
            });
            button.on('execute', () => {
                // Get all blocks currently selected in the editor
                const blocksGenerator = editor.model.document.selection.getSelectedBlocks();
                // Get the first block in the Iterable of blocks
                const firstBlock = blocksGenerator.next().value;
                // Extract ID from the block
                const blockId = firstBlock.getAttribute('block-id');
                // Select the whole block in the editor
                editor.model.change(writer => {
                    writer.setSelection(firstBlock, 'on');
                });
                commentsRepository.fire('openBlockComment', { anchorId: blockId });
            });
            return button;
        };
        // The button must be registered among the UI components of the editor
        // to be displayed in the toolbar.
        editor.ui.componentFactory.add('blockComment', () => createToolbarButton());
        /**
         * Listen to the event we fire when opening a new block comment thread
         * The event data will contain the anchor id ( the id of the node ).
         * We will also pre-generate a thread ID to be used later.
         */
        commentsRepository.on('openBlockComment', (evt, data) => {
            const { anchorId } = data;
            const threadId = uuid();
            this.editor.execute('addCommentThread', { threadId });
            const thread = commentsRepository.getCommentThread(threadId);
            if (thread === null || thread === void 0 ? void 0 : thread.attributes) {
                thread.attributes = {
                    anchorId,
                };
            }
        });
    }
}
