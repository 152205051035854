var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { ChatWithInsightsDocument } from './Chat.gql';
function getData(data) {
    return data === null || data === void 0 ? void 0 : data.convertNaturalLanguageToSearchInsightsWidget;
}
export function useGenerateInsightWidget() {
    const [mutation, state] = useMutation(ChatWithInsightsDocument);
    const { data, error, loading, reset, called } = state;
    const transformedData = getData(data);
    const generate = (query) => __awaiter(this, void 0, void 0, function* () {
        const response = yield mutation({
            fetchPolicy: 'network-only',
            variables: {
                query,
            },
        });
        return getData(response.data);
    });
    const getErrorMessage = () => {
        var _a;
        if (error) {
            return t({
                message: 'Generation error',
                comment: 'Insights chat - Generic error message',
            });
        }
        if (called && !loading && !((_a = transformedData === null || transformedData === void 0 ? void 0 : transformedData.aggregations) === null || _a === void 0 ? void 0 : _a.length)) {
            return t({
                message: 'No data',
                comment: 'Insights chat - LLM did not return any data',
            });
        }
        return undefined;
    };
    return {
        generate,
        reset,
        data: transformedData,
        error: getErrorMessage(),
        loading,
        called,
    };
}
