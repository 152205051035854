import { useState } from 'react';
import { Box } from '@pocketlaw/tetris';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { Legend } from './Legend';
import { SliceInfo } from './SliceInfo';
import { EmptyVisualization } from '../EmptyVisualization';
import { TooltipContent } from '../TooltipContent';
import { Unsupported } from '../Unsupported';
import { VisualizationMeta } from '../VisualizationMeta';
import { TOOLTIP_CURSOR_FILL } from '../constants';
import { getTheme } from '../getTheme';
import { transformAggregation } from '../transformData';
import { bucketTypeGuard } from '../typeGuards';
import { ChartContainer, Container } from './styled';
export function DoughnutChart(props) {
    const { data, totalHitsCount, aggregations, colorScheme } = props;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations || [];
    const isBucketAggregation = bucketTypeGuard(dataPoint);
    const { entries, keys, missing, count } = transformAggregation(dataPoint, aggregation);
    const [active, setActive] = useState();
    const handleSelect = (slice) => {
        const { name } = slice || {};
        setActive(name);
    };
    if (!isBucketAggregation) {
        return <Unsupported />;
    }
    if (!dataPoint || !keys.length) {
        return <EmptyVisualization />;
    }
    const { sumOtherCount } = dataPoint;
    const theme = getTheme(colorScheme);
    return (<Box flexDirection="column" width="100%" height="100%">
      <VisualizationMeta count={count} missing={missing} sumOtherCount={sumOtherCount || 0} totalHitsCount={totalHitsCount}/>
      <Container>
        <ChartContainer>
          <SliceInfo data={entries} active={active} total={totalHitsCount || 0}/>
          <PieChart width={250} height={250} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
            <Tooltip animationDuration={200} cursor={{ fill: TOOLTIP_CURSOR_FILL }} shared={false} content={<TooltipContent />}/>
            <Pie dataKey="total" cy="50%" cx="50%" outerRadius="100%" data={entries} isAnimationActive={false} innerRadius="70%" onMouseOver={handleSelect} onMouseOut={() => handleSelect()} minAngle={5}>
              {entries.map((entry, entryIndex) => (<Cell key={entry.name} fill={theme('single', entryIndex)} opacity={active && active !== entry.name ? 0.125 : 1}/>))}
            </Pie>
          </PieChart>
        </ChartContainer>
        <Legend data={entries} onSelect={handleSelect}/>
      </Container>
    </Box>);
}
