import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, IconButton, TextArea } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { PromptLibraryModal, PromptLibraryProvider } from 'app/domains/prompt-library';
import { useFeatureFlag } from 'shared/domains/featureFlag';
export function ChatInput(props) {
    const { placeholder, initialValue = '', disabled = false, onSendMessage } = props;
    const [value, setValue] = useState(initialValue);
    const [promptLibraryOpen, setPromptLibraryOpen] = useState(false);
    const isPromptLibraryAvailable = useFeatureFlag('feature_prompt_library');
    const isSendDisabled = disabled || value.length === 0;
    const onChangeValue = (event) => setValue(event.target.value);
    const handleOpenPromptLibrary = () => setPromptLibraryOpen(true);
    const handleClosePromptLibrary = () => setPromptLibraryOpen(false);
    const handleSelectPrompt = (prompt) => {
        setValue(prompt);
        handleClosePromptLibrary();
    };
    const handleSendMessage = () => {
        if (isSendDisabled) {
            return;
        }
        onSendMessage(value);
        setValue('');
    };
    const handleKeyDown = (event) => {
        if (isSendDisabled) {
            return;
        }
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };
    return (<Box flexDirection="column" gap="md">
      <TextArea autoFocus autosizeHeight maxHeight={260} appearance="transparent" value={value} placeholder={placeholder} onChange={onChangeValue} onKeyDown={handleKeyDown}/>
      <Box justifyContent="space-between">
        <Box gap="sm">
          <Button size="small" appearance="ghost" onClick={handleOpenPromptLibrary}>
            <Falcon icon="book-bookmark"/>
            <Trans comment="Chat input actions - Prompts label">Prompts</Trans>
          </Button>
        </Box>
        <IconButton size="small" appearance="primary" disabled={disabled || value.length === 0} onClick={handleSendMessage}>
          <Falcon icon="arrow-right"/>
        </IconButton>
      </Box>
      {isPromptLibraryAvailable && promptLibraryOpen && (<PromptLibraryProvider defaultView="library" onSelect={handleSelectPrompt} onClose={handleClosePromptLibrary}>
          <PromptLibraryModal />
        </PromptLibraryProvider>)}
    </Box>);
}
