import { Command } from 'ckeditor5';
export class HighlightResetCommand extends Command {
    static get pluginName() {
        return 'highlightReset';
    }
    constructor(editor, state) {
        super(editor);
        this.state = state;
    }
    execute() {
        const { model } = this.editor;
        this.state.clear(model);
    }
}
