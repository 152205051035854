import { useFileBrowserContext } from 'app/domains/filesystem';
import { PickerBreadcrumbs } from '../PickerBreadcrumbs';
import { PickerDialog } from '../PickerDialog';
import { PickerFooter } from '../PickerFooter';
import { PickerHeader } from '../PickerHeader';
import { PickerTableFilesystem } from '../PickerTableFilesystem';
export function FileDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, selectedEntryId, selectedEntryAlert, onClose, onSubmit, } = props;
    const { fileBrowserSearch } = useFileBrowserContext();
    const { search, searchQuery, clearSearch } = fileBrowserSearch;
    return (<PickerDialog>
      <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose} search={search} searchQuery={searchQuery} clearSearch={clearSearch}/>
      <PickerBreadcrumbs />
      <PickerTableFilesystem selectType="document" selectedEntryId={selectedEntryId} selectedEntryAlert={selectedEntryAlert}/>
      <PickerFooter showPagination hasSelection={Boolean(selectedEntryId)} submitLabel={submitLabel} disabled={!selectedEntryId} onSubmit={onSubmit}/>
    </PickerDialog>);
}
