import { t } from '@lingui/macro';
import { useFormikContext } from 'formik';
import { CreateSignRequestErrors, CreateSignRequestFailedSubcodes, } from 'app/domains/esigning/components/EsigningForm/types';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { BasicAlert } from './BasicAlert';
import { LatestAttemptFailed } from './LatestAttemptFailed';
import { NoApprovalError } from './NoApprovalError';
export function ErrorAlert() {
    const { submitCount } = useFormikContext();
    const { failed, submittingCreation, createSignRequestError, createSignRequestFailedSubcode, lastSignRequestAttemptTimestamp, } = useEsigning();
    if (createSignRequestFailedSubcode) {
        switch (createSignRequestFailedSubcode) {
            case CreateSignRequestFailedSubcodes.AttachmentUploadFailed:
                return (<BasicAlert message={t({
                        comment: 'The upload failed for the attachment - generic error message',
                        message: 'Failed to upload one or more attachments, please try again',
                    })}/>);
            case CreateSignRequestFailedSubcodes.AttachmentUploadFailedInvalidPdf:
                return (<BasicAlert message={t({
                        comment: 'The upload failed for the attachment - invalid pdf message',
                        message: 'One or more attachments cannot be processed because they are protected. Please ensure your PDF attachments are unprotected and try again.',
                    })}/>);
            default:
                return null;
        }
    }
    // The latest sign request attempt failed but the user has reloaded the app since
    if (submitCount === 0 && failed) {
        return <LatestAttemptFailed latestAttemptTimestamp={lastSignRequestAttemptTimestamp}/>;
    }
    if (submitCount === 0 || submittingCreation) {
        return null;
    }
    if (failed) {
        return (<BasicAlert message={t({
                message: 'The e-signing has failed. Please try again.',
                comment: 'Error message when e-signing has failed and goes back to the form',
            })}/>);
    }
    switch (createSignRequestError) {
        case CreateSignRequestErrors.NoCredits:
            return (<BasicAlert message={t({
                    message: 'You have insufficient credits on your account to do this action',
                    comment: 'Error message for when user tries to send out document for signing when the company has 0 credits',
                })}/>);
        case CreateSignRequestErrors.NoSignatory:
            return (<BasicAlert message={t({
                    message: 'You must add at least one signing party',
                    comment: 'Error message for when a user tries to send out document for signing with no parties',
                })}/>);
        case CreateSignRequestErrors.NoScriveToken:
            return (<BasicAlert message={t({
                    message: 'You have insufficient credits on your account to do this action. Please contact Scrive for troubleshooting',
                    comment: 'Error message for when user tries to send out document for signing when no scrive tokens',
                })}/>);
        case CreateSignRequestErrors.NoApproval:
            return <NoApprovalError />;
        case CreateSignRequestErrors.UnexpectedError:
            return (<BasicAlert message={t({
                    message: 'Something went wrong, please try again later',
                    comment: 'Unexpected error happened',
                })}/>);
        default:
            return null;
    }
}
