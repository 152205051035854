import { H1, H2, H3, H4, H5, H6, Link, pxToRem, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const BLOCK_MARGIN_SM = 'spacing.sm';
const BLOCK_MARGIN_MD = 'spacing.md';
const BLOCK_MARGIN_LG = 'spacing.2xl';
const blockStyles = css `
  margin-top: ${themeGet(BLOCK_MARGIN_MD)};
  margin-bottom: ${themeGet(BLOCK_MARGIN_MD)};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
const paragraphStyles = css `
  font-size: ${themeGet('fontSize.medium')};
  font-style: ${themeGet('fontStyle.normal')};
  font-weight: ${themeGet('fontWeight.regular')};
  line-height: ${themeGet('lineHeight.default')};
  color: ${themeGet('colors.text.300')};
`;
const listStyles = css `
  ${blockStyles};
  padding-left: ${themeGet(BLOCK_MARGIN_LG)};

  & ol,
  & ol {
    list-style-type: lower-roman;
  }

  & ul ol,
  & ol ol {
    list-style-type: lower-alpha;
  }
`;
const cellStyles = css `
  ${paragraphStyles};
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
`;
export const Heading1 = styled(H1) `
  ${blockStyles};
`;
export const Heading2 = styled(H2) `
  ${blockStyles};
`;
export const Heading3 = styled(H3) `
  ${blockStyles};
`;
export const Heading4 = styled(H4) `
  ${blockStyles};
`;
export const Heading5 = styled(H5) `
  ${blockStyles};
`;
export const Heading6 = styled(H6) `
  ${blockStyles};
`;
export const Paragraph = styled.p `
  ${blockStyles};
  ${paragraphStyles};
`;
export const Anchor = styled(Link) ``;
export const Strong = styled.strong `
  font-weight: ${themeGet('fontWeight.bold')};
`;
export const Emphasis = styled.em `
  font-style: ${themeGet('fontStyle.italic')};
`;
export const Delete = styled.del `
  text-decoration-line: line-through;
`;
export const BlockQuote = styled.blockquote `
  margin: ${themeGet(BLOCK_MARGIN_LG)} 0;
  padding: 0 ${themeGet(BLOCK_MARGIN_LG)};
  color: ${themeGet('colors.text.100')};
  border-left: solid ${pxToRem(4)} ${themeGet('colors.border.100')};

  & > p {
    color: inherit;
  }
`;
export const Hairline = styled.hr `
  border: 0;
  padding: 0;
  margin: ${themeGet(BLOCK_MARGIN_LG)} 0;
  overflow: hidden;
  box-sizing: content-box;
  background: transparent;
  background-color: ${themeGetColor('border.100')};
  height: ${pxToRem(1)};
`;
export const UnorderedList = styled.ul `
  ${listStyles};
  list-style-type: disc;
`;
export const OrderedList = styled.ol `
  ${listStyles};
  list-style-type: decimal;
`;
export const ListItem = styled.li `
  ${paragraphStyles};

  & + li {
    margin-top: ${themeGet(BLOCK_MARGIN_SM)};
  }
`;
export const Table = styled.table `
  ${blockStyles};
  border-spacing: 0;
  border-collapse: separate;
  display: block;
  max-width: 100%;
  overflow: auto;
  text-align: left;

  --table-border: solid ${pxToRem(1)} ${themeGetColor('border.100')};
  --table-border-radius: ${themeGet('borderRadius.md')};

  tr th,
  tr td {
    border-right: var(--table-border);
    border-bottom: var(--table-border);
  }

  tr th:first-child,
  tr td:first-child {
    border-left: var(--table-border);
  }

  tr th {
    border-top: var(--table-border);
    background-color: ${themeGet('colors.background.75')};
  }

  /* top-left border-radius */
  tr:first-child th:first-child {
    border-top-left-radius: var(--table-border-radius);
  }

  /* top-right border-radius */
  tr:first-child th:last-child {
    border-top-right-radius: var(--table-border-radius);
  }

  /* bottom-left border-radius */
  tr:last-child td:first-child {
    border-bottom-left-radius: var(--table-border-radius);
  }

  /* bottom-right border-radius */
  tr:last-child td:last-child {
    border-bottom-right-radius: var(--table-border-radius);
  }
`;
export const TableRow = styled.tr ``;
export const TableHead = styled.th `
  ${cellStyles};
  font-weight: ${themeGet('fontWeight.bold')};
`;
export const TableDataCell = styled.td `
  ${cellStyles};
`;
