import { getBucketData, transformMetricBucket } from './getBucketData';
import { TERMS_AGGREGATION_MISSING_VALUE_NAME } from '../../../constants';
import { getNextAggregations } from '../getNextAggregations';
import { bucketTypeGuard, metricTypeGuard } from '../typeGuards';
/**
 * Transforms the aggregation response into a format that is consumable by the visualization components.
 *
 * @param dataPoint
 * @param aggregation
 */
export function transformAggregation(dataPoint, aggregation) {
    const isBucketedResponse = bucketTypeGuard(dataPoint);
    if (!isBucketedResponse) {
        return {
            entries: [],
            keys: [],
            missing: 0,
            count: 0,
        };
    }
    const { buckets } = dataPoint;
    const transformedData = buckets === null || buckets === void 0 ? void 0 : buckets.reduce((acc, bucket) => {
        const { name, value, aggregations: subDataPoints } = bucket;
        const { keys = [], entries, missing } = acc;
        const breakdowns = Array.isArray(subDataPoints) ? subDataPoints : [];
        const newKeys = [...keys];
        const data = getBucketData(bucket, aggregation);
        const [breakdownAggregation] = getNextAggregations([aggregation]);
        if (breakdowns.length) {
            // Calculate the total value from breakdowns, which may differ from the main metric's total.
            // This discrepancy occurs because breakdowns might not cover all data points.
            // For instance, if the main metric is Contract owner and the breakdown is Contract type,
            // the breakdown total may be less than the main metric total, as not all contracts have a specified type.
            // Contract owner slice - The contract owner might have 1000 contracts
            // Contract type slice - The contract type might have 1000 contracts, but only 50 have a specified type.
            let totalValueFromBreakdowns = 0;
            breakdowns.forEach(subDataPoint => {
                if (bucketTypeGuard(subDataPoint)) {
                    subDataPoint.buckets.forEach(subAggregationBucket => {
                        const transformedBucket = getBucketData(subAggregationBucket, breakdownAggregation);
                        newKeys.push(transformedBucket.name);
                        totalValueFromBreakdowns += transformedBucket.total;
                        Object.assign(data, {
                            [transformedBucket.name]: transformedBucket.total,
                            breakdownPayloads: [...data.breakdownPayloads, transformedBucket.payload],
                        });
                    });
                }
                if (metricTypeGuard(subDataPoint)) {
                    const transformedBucket = transformMetricBucket(subDataPoint, breakdownAggregation);
                    newKeys.push('total');
                    totalValueFromBreakdowns += transformedBucket.total;
                    Object.assign(data, {
                        total: transformedBucket.total,
                        tooltip: transformedBucket.tooltip,
                        breakdownPayloads: [...data.breakdownPayloads, transformedBucket.payload],
                    });
                }
            });
            data.total = totalValueFromBreakdowns;
        }
        if (!breakdowns.length) {
            newKeys.push('total');
        }
        if (name === TERMS_AGGREGATION_MISSING_VALUE_NAME) {
            return {
                entries,
                keys,
                missing: value,
            };
        }
        return {
            entries: [...entries, data],
            keys: newKeys,
            missing,
        };
    }, {
        entries: [],
        keys: [],
        missing: 0,
    });
    const { entries, keys, missing } = transformedData;
    const uniqueKeys = [...new Set(keys)];
    const count = entries.reduce((acc, curr) => acc + curr.total, 0);
    const entriesHasBreakdown = entries.map(entry => Object.keys(entry).length > 1).some(Boolean);
    const hasBreakdowns = entriesHasBreakdown && entries.length > 1;
    return {
        entries,
        missing,
        count,
        keys: uniqueKeys,
        breakdown: hasBreakdowns,
    };
}
