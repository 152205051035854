import { useState } from 'react';
import { Avatar, IconButton, Typography } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { MessageLoader } from './MessageLoader';
import { markdownToReact } from './markdownToReact';
import { MessageActions, MessageBubble, MessageContainer, MessageHeader } from './styled';
export function Message(props) {
    const { message, align, appearance, avatar, loading = false, streaming = false, actions = [], showActionsDefault = false, components, children, } = props;
    const { sender, timestamp, text, markdown, isFirst } = message;
    const [showActions, setShowActions] = useState(false);
    const handleMouseOver = () => setShowActions(true);
    const handleMouseLeave = () => setShowActions(false);
    const isMessageReady = Boolean(text || markdown) && !loading && !streaming;
    const renderActions = actions.length > 0 && (showActions || showActionsDefault) && isMessageReady;
    const time = localizedFormatDate(timestamp, {
        day: '2-digit',
        month: 'short',
        hour: 'numeric',
        minute: 'numeric',
    });
    return (<MessageContainer $align={align} $appearance={appearance} $showActions={renderActions} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {isFirst && (<MessageHeader $align={align}>
          {avatar && (<Avatar solid size="sm" appearance={avatar.appearance}>
              {avatar.icon}
            </Avatar>)}
          <Typography $fontSize="small" $appearance="300">
            {sender}
          </Typography>
          <Typography $fontSize="tiny" $appearance="100">
            {time}
          </Typography>
        </MessageHeader>)}
      <MessageLoader show={loading}/>
      <MessageBubble $streaming={streaming && !loading}>
        {text && (<Typography $fontSize="medium" $appearance="300">
            {text}
          </Typography>)}
        {markdown && markdownToReact({ markdown, components })}
        {isMessageReady && children}
      </MessageBubble>
      {actions.length > 0 && (<MessageActions>
          {actions.map(action => (<IconButton key={action.tooltip} size="xs" appearance="ghost" aria-label={action.tooltip} onClick={action.onClick}>
              {action.icon}
            </IconButton>))}
        </MessageActions>)}
    </MessageContainer>);
}
