var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useParams } from 'react-router-dom';
import { useMoveEntry } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { PickFolderDialog } from 'shared/domains/common-ui';
import { useEntryName } from './useEntryName';
export function MoveDialog(props) {
    const { type, payload, parentId, onClose } = props;
    const { folderId } = useParams();
    const { id } = payload;
    const { addToast } = useToast();
    const { entryName } = useEntryName(id);
    const [move, { loading }] = useMoveEntry({ kind: type, id });
    const handleSubmit = (entry) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield move(entry.id);
            onClose();
            if (type === EntryKind.Folder) {
                addToast({
                    appearance: 'success',
                    title: t({
                        message: `Folder moved to ${entry.name}`,
                        comment: 'Move folder success toast',
                    }),
                });
            }
            else {
                addToast({
                    appearance: 'success',
                    title: t({
                        message: `Document moved to ${entry.name}`,
                        comment: 'Move document success toast',
                    }),
                });
            }
        }
        catch (_a) {
            if (type === EntryKind.Folder) {
                addToast({
                    appearance: 'danger',
                    title: t({
                        message: 'Error occurred while moving folder',
                        comment: 'Move folder error toast',
                    }),
                });
            }
            else {
                addToast({
                    appearance: 'danger',
                    title: t({
                        message: 'Error occurred while moving document',
                        comment: 'Move document error toast',
                    }),
                });
            }
        }
    });
    const getLoaderOverlayOptions = () => {
        if (type === EntryKind.Folder) {
            return {
                loading,
                title: t({
                    comment: 'Move folder dialog loading title',
                    message: 'Moving folder',
                }),
                description: t({
                    comment: 'Move folder dialog loading description',
                    message: 'Please wait while we are moving the folder...',
                }),
            };
        }
        return {
            loading,
            title: t({
                comment: 'Move document dialog loading title',
                message: 'Moving document',
            }),
            description: t({
                comment: 'Move document dialog loading description',
                message: 'Please wait while we are moving the document...',
            }),
        };
    };
    const getSubtitle = () => {
        if (type === EntryKind.Folder) {
            return t({
                comment: 'Move folder dialog subtitle',
                message: 'Choose where you want to move the folder',
            });
        }
        return t({
            comment: 'Move document dialog subtitle',
            message: 'Choose where you want to move the document',
        });
    };
    return (<PickFolderDialog initialFolderId={parentId || folderId} dialogTitle={t({
            comment: 'Move document/folder dialog title',
            message: `Move ${entryName}`,
        })} dialogSubtitle={getSubtitle()} submitLabel={t({ comment: 'Move document dialog submit label', message: 'Move' })} loaderOverlay={getLoaderOverlayOptions()} onSubmit={handleSubmit} onClose={onClose}/>);
}
