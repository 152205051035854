import { Box, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function TooltipContent(props) {
    const { active, payload } = props;
    const display = active && payload && payload.length;
    if (!display) {
        return null;
    }
    const [slice] = payload || [];
    const label = (slice === null || slice === void 0 ? void 0 : slice.name) !== 'total' ? slice.name : slice.payload.name;
    const values = Object.entries(slice.payload).filter(([key]) => !['breakdownPayloads', 'name', 'total', 'payload', 'tooltip'].includes(key));
    if (slice.payload.tooltip) {
        return (<Box bg="primary.900" borderRadius="md" p="lg" gap="xl" flexDirection="column">
        <Typography $appearance="light">
          {label} - {slice.payload.tooltip}
        </Typography>
      </Box>);
    }
    return (<Box bg="primary.900" borderRadius="md" p="lg" gap="xl" flexDirection="column">
      <Box alignItems="center">
        <Box flexDirection="column" gap="xs">
          {(slice === null || slice === void 0 ? void 0 : slice.name) !== 'total' && (<Typography $fontSize="tiny" $appearance="light" $fontWeight="bold" $lineHeight="neutralized">
              {slice.payload.name}
            </Typography>)}
          <Box gap="sm" alignItems="center">
            <Typography $appearance="light" $fontWeight="bold" $fontSize="large" $lineHeight="neutralized">
              {label}:
            </Typography>
            <Typography $appearance="light" $lineHeight="neutralized" $fontSize="large">
              {slice.value}
            </Typography>
          </Box>
        </Box>

        <Box ml="auto" pl="lg">
          <Falcon icon="arrow-up-right-from-square" $color="text.light"/>
        </Box>
      </Box>

      {Boolean(values.length) && (<Box flexDirection="column" gap="md">
          {values.map(([key, value]) => (<Typography key={key} $fontSize="small" $appearance="light" $fontWeight="bold" $lineHeight="neutralized">
              {key}: {value} documents
            </Typography>))}
        </Box>)}
    </Box>);
}
