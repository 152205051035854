import { metadataConfig } from 'shared/domains/metadata';
import { withFilterErrorBoundary } from './FilterErrorBoundary';
import { SearchStringFilter } from './SearchStringFilter';
/**
 * This is a generic multiselect filter that can be used to filter on any metadata property that has allowed values.
 */
export const MetadataAllowedValuesFilter = withFilterErrorBoundary((props) => {
    var _a, _b;
    const { name, field, label } = props;
    const items = (_b = (_a = metadataConfig.get(name)) === null || _a === void 0 ? void 0 : _a.allowedValues) === null || _b === void 0 ? void 0 : _b.reduce((acc, current) => {
        const { text, value, groupValues } = current;
        const newValues = [];
        if (value && value.length) {
            newValues.push({ title: text, id: value });
        }
        groupValues === null || groupValues === void 0 ? void 0 : groupValues.forEach(groupValue => {
            newValues.push({ title: groupValue.text, id: groupValue.value });
        });
        return [...acc, ...newValues];
    }, []);
    return <SearchStringFilter name={field} items={items || []} label={label}/>;
});
