import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { useTemplateBrowserContext } from 'app/domains/templates';
import { ToggleTabs, ToggleTabsButtonsContainer, ToggleTabsButton, ToggleTabsContent, } from '../../ToggleTabs';
import { PickerBreadcrumbs } from '../PickerBreadcrumbs';
import { PickerDialog } from '../PickerDialog';
import { PickerFooter } from '../PickerFooter';
import { PickerHeader } from '../PickerHeader';
import { PickerTableFilesystem } from '../PickerTableFilesystem';
import { PickerTableTemplates } from '../PickerTableTemplates';
export function FileTemplateDialog(props) {
    const { dialogTitle, dialogSubtitle, submitLabel, selectedEntryId, selectedTemplateId, selectedEntryAlert, onClose, onSubmitDocument, onSubmitTemplate, } = props;
    const { fileBrowserSearch } = useFileBrowserContext();
    const { templateBrowserSearch } = useTemplateBrowserContext();
    const [activeTab, setActiveTab] = useState('filesystem');
    const isDocumentTab = activeTab === 'filesystem';
    const toggleTabsSpacing = activeTab === 'filesystem' ? 'xl' : '2xl';
    const submitDisabled = (activeTab === 'filesystem' && !selectedEntryId) ||
        (activeTab === 'templates' && !selectedTemplateId);
    const hasSelection = (activeTab === 'filesystem' && selectedEntryId !== undefined) ||
        (activeTab === 'templates' && selectedTemplateId !== undefined);
    const searchQuery = isDocumentTab
        ? fileBrowserSearch.searchQuery
        : templateBrowserSearch.searchQuery;
    const handleSearch = (query) => {
        if (isDocumentTab) {
            fileBrowserSearch.search(query);
        }
        else {
            templateBrowserSearch.search(query);
        }
    };
    const handleClearSearch = () => {
        if (isDocumentTab) {
            fileBrowserSearch.clearSearch();
        }
        else {
            templateBrowserSearch.clearSearch();
        }
    };
    const handleSubmit = () => {
        if (isDocumentTab) {
            onSubmitDocument();
        }
        else {
            onSubmitTemplate();
        }
    };
    return (<PickerDialog>
      <PickerHeader title={dialogTitle} subtitle={dialogSubtitle} onClose={onClose} search={handleSearch} searchQuery={searchQuery} clearSearch={handleClearSearch}/>
      <ToggleTabs activeTab={activeTab} onSelectTab={setActiveTab}>
        <Box pl="2xl" pr="2xl" pb={toggleTabsSpacing}>
          <ToggleTabsButtonsContainer stretch size="small">
            <ToggleTabsButton tabKey="filesystem">
              <Trans comment="Documents label">Documents</Trans>
            </ToggleTabsButton>
            <ToggleTabsButton tabKey="templates">
              <Trans comment="Templates label">Templates</Trans>
            </ToggleTabsButton>
          </ToggleTabsButtonsContainer>
        </Box>
        <ToggleTabsContent tabKey="filesystem">
          <PickerBreadcrumbs />
          <PickerTableFilesystem selectType="document" selectedEntryId={selectedEntryId} selectedEntryAlert={selectedEntryAlert}/>
        </ToggleTabsContent>
        <ToggleTabsContent tabKey="templates">
          <PickerTableTemplates selectedTemplateId={selectedTemplateId}/>
        </ToggleTabsContent>
      </ToggleTabs>
      <PickerFooter showPagination={isDocumentTab} submitLabel={submitLabel} disabled={submitDisabled} hasSelection={hasSelection} onSubmit={handleSubmit}/>
    </PickerDialog>);
}
