import { Plugin } from 'ckeditor5';
import { AssistantReplaceCommand } from './replacecommand';
import { AssistantResetCommand } from './resetcommand';
import { AssistantSelectionCommand } from './selectioncommand';
import { AssistantState } from './state';
export class AssistantSelection extends Plugin {
    static get pluginName() {
        return 'AssistantSelection';
    }
    init() {
        this.state = new AssistantState();
        this.defineConverters();
        this.defineCommands();
    }
    afterInit() {
        const { editor } = this;
        // Enable commands in restricted editing mode
        if (editor.plugins.has('RestrictedEditingModeEditing')) {
            const restrictedEditingPlugin = this.editor.plugins.get('RestrictedEditingModeEditing');
            restrictedEditingPlugin.enableCommand(AssistantSelectionCommand.pluginName);
            restrictedEditingPlugin.enableCommand(AssistantResetCommand.pluginName);
        }
    }
    defineConverters() {
        const { editor } = this;
        // Marker
        editor.conversion.for('editingDowncast').markerToHighlight({
            model: 'assistant',
            view: ({ markerName }) => {
                const [, id] = markerName.split(':');
                if (!id) {
                    return null;
                }
                return {
                    name: 'span',
                    classes: ['pl-assistant--selection'],
                    attributes: {
                        'data-highlight-id': id,
                    },
                };
            },
        });
    }
    defineCommands() {
        const { editor, state } = this;
        const selectionCommandName = AssistantSelectionCommand.pluginName;
        editor.commands.add(selectionCommandName, new AssistantSelectionCommand(editor, state));
        const resetCommandName = AssistantResetCommand.pluginName;
        editor.commands.add(resetCommandName, new AssistantResetCommand(editor, state));
        const replaceCommandName = AssistantReplaceCommand.pluginName;
        editor.commands.add(replaceCommandName, new AssistantReplaceCommand(editor, state));
    }
}
