import { Plugin } from 'shared/domains/pleditor@next';
export class PleditorPlugin extends Plugin {
    constructor(editor) {
        super(editor);
        this.documentId = this.editor.config.get('documentId');
        this.apiConnector = this.editor.plugins.get('ApiConnector');
        this.userPermissions = this.editor.plugins.get('Permissions');
        this.isExternal = process.env.WEB_CLIENT === 'external';
    }
}
