import { scrollbar, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const OuterContainer = styled.div `
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: ${themeGet('spacing.md')};
`;
export const InnerContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: ${themeGet('spacing.xl')};
  overflow-y: auto;
  padding: ${themeGet('spacing.md')};
  ${scrollbar()}
`;
