import { Box, Button, DialogFooter } from '@pocketlaw/tetris';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { Pagination } from 'shared/domains/common-ui';
import { CreateFolderPopover } from '../CreateFolderPopover';
export function PickerFooter(props) {
    const { submitLabel, showPagination, showCreateFolder = false, disabled = false, hasSelection = false, onSubmit, } = props;
    const { loading, limit, offset, setOffset, count, fileBrowserSearch } = useFileBrowserContext();
    const { searching } = fileBrowserSearch;
    const submitDisabled = (loading || disabled || searching) && !hasSelection;
    return (<DialogFooter description={!searching && (<Box alignItems="center" gap="sm">
            {showCreateFolder && <CreateFolderPopover />}
            {showPagination && (<Pagination count={count} maxCount={10000} limit={limit} offset={offset} setOffset={setOffset} disabled={loading}/>)}
          </Box>)}>
      <Button appearance="primary" disabled={submitDisabled} onClick={onSubmit}>
        {submitLabel}
      </Button>
    </DialogFooter>);
}
