import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Container } from './styled';
export function PasswordProtected() {
    return (<Container>
      <EmptyState withAvatar icon={<Falcon icon="lock"/>} title={t({
            comment: 'PDF Preview - PDF password protected title',
            message: 'Password protected',
        })} description={t({
            comment: 'PDF Preview - PDF password protected description',
            message: 'This PDF is password protected',
        })}/>
    </Container>);
}
