import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
export function PasswordProtectedPdfAlert() {
    return (<Alert appearance="danger" title={t({
            comment: 'eSigning password protected pdf - Alert title for when the pdf is password protected',
            message: 'The PDF is password protected',
        })} message={t({
            comment: 'eSigning password protected pdf - Alert message for when the pdf is password protected',
            message: 'Please ensure that no editing, extracting or printing restrictions has been added to this file',
        })}/>);
}
