import { Fragment, useEffect, useRef } from 'react';
import { addMetadataToMessages, groupMessages, revertMessagesOrder } from './utils';
import { InnerContainer, OuterContainer } from './styled';
export function Chat(props) {
    const { messages, renderMessage } = props;
    const containerRef = useRef(null);
    const groupedMessages = addMetadataToMessages(groupMessages(messages));
    // We revert the order due to how we display the messages with css `flex-direction: column-reverse`
    const revertedGroups = revertMessagesOrder(groupedMessages);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight + 10, // 10 px added for good measure
                behavior: 'smooth',
            });
        }
    }, [messages.length]);
    return (<OuterContainer>
      <InnerContainer ref={containerRef}>
        {revertedGroups.map(group => (<Fragment>{group.map(renderMessage)}</Fragment>))}
      </InnerContainer>
    </OuterContainer>);
}
