var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t, Trans } from '@lingui/macro';
import { useToast, Typography, Avatar, ConfirmDialog } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMoveEntry } from 'app/domains/filesystem';
export function QuickMoveDialog(props) {
    const { type, payload, onClose } = props;
    const { sourceId, sourceName, targetId, targetName } = payload;
    const [move, { loading }] = useMoveEntry({ kind: type, id: sourceId });
    const { addToast } = useToast();
    const handleConfirm = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield move(targetId);
            onClose();
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Document moved',
                    comment: 'Move document success toast',
                }),
            });
        }
        catch (_a) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Error occurred while moving document',
                    comment: 'Move document error toast',
                }),
            });
        }
    });
    if (sourceId === targetId) {
        // Can happen if you move a folder onto itself, no error is expected, just do nothing
        return null;
    }
    return (<ConfirmDialog media={<Avatar appearance="primary" shape="square" size="md">
          <Falcon icon="arrow-right"/>
        </Avatar>} heading={<Trans comment="Title for quick move document dialog (from document view and files)">
          Move document
        </Trans>} appearance="primary" size="sm" loading={loading} confirm={<Trans comment="Delete document button label">Move</Trans>} cancel={<Trans comment="Cancel button label">Cancel</Trans>} onConfirm={handleConfirm} onCancel={onClose}>
      <Typography $appearance="200">
        <Trans comment="Confirm message for quick move document dialog">
          Do you want to move{' '}
          <Typography $fontWeight="bold" $appearance="300">
            {sourceName}
          </Typography>{' '}
          to{' '}
          <Typography $fontWeight="bold" $appearance="300">
            {targetName}
          </Typography>
          ?
        </Trans>
      </Typography>
    </ConfirmDialog>);
}
