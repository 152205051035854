import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { BalloonToolbar, DecoupledEditor, RestrictedEditingMode, Assistant, ASSISTANT_COMPONENT_NAME, HighlightPlugin, } from 'shared/domains/pleditor@next';
import * as Sentry from 'shared/domains/sentry';
import { getDocumentQuery } from './query';
import { usePleditor } from '../PleditorProvider';
import { PleditorError } from '../components/PleditorError';
import { PleditorLoader } from '../components/PleditorLoader';
import { useIsLayoutReady } from '../hooks/useIsLayoutReady';
import { usePleditorAssistantConfig } from '../hooks/usePleditorAssistantConfig';
import { ReviewPlugin } from '../plugins/Review';
import { PREVIEW_PLUGINS } from '../previewPlugins';
import { BasePleditorStyles, PleditorWrapper, PleditorContainer } from '../styled';
export function DocumentPreview(props) {
    var _a;
    const { document } = props;
    const isLayoutReady = useIsLayoutReady();
    const { data, loading, error } = useQuery(getDocumentQuery, { variables: { documentId: document.id } });
    const { setPleditor } = usePleditor();
    const assistantConfig = usePleditorAssistantConfig();
    if (loading) {
        return <PleditorLoader />;
    }
    if (error) {
        return <PleditorError />;
    }
    const baseConfig = {
        licenseKey: process.env.PLEDITOR_LICENSE_KEY,
        assistant: assistantConfig,
    };
    const config = Object.assign(Object.assign({}, baseConfig), { extraPlugins: [
            ...PREVIEW_PLUGINS,
            BalloonToolbar,
            RestrictedEditingMode,
            Assistant,
            ReviewPlugin,
            HighlightPlugin,
        ], balloonToolbar: { items: [ASSISTANT_COMPONENT_NAME] } });
    return (<Fragment>
      <BasePleditorStyles />
      <PleditorWrapper>
        <PleditorContainer $isDocumentPreview>
          {isLayoutReady && (<CKEditor config={config} editor={DecoupledEditor} data={((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.htmlBody) || ''} onReady={editor => setPleditor('DocumentPreview', editor)} onError={err => Sentry.captureException(err, { tags: { feature: 'pleditor' } })}/>)}
        </PleditorContainer>
      </PleditorWrapper>
    </Fragment>);
}
