import { t } from '@lingui/macro';
import { SearchAggregationsAllowedPropertiesForMetricAggregation as AllowedProperties, SearchAggregationMetricType, } from 'shared/domains/apollo/generated/types';
export function allowedPropertiesTranslations(key) {
    const fallback = t({
        message: 'Unknown',
        comment: 'Insights: Metric allowed fields: Unknown',
    });
    if (!key) {
        return fallback;
    }
    const translations = {
        [AllowedProperties.EntityId]: t({
            message: 'Contract ID',
            comment: 'Insights: Metric allowed fields: EntityId',
        }),
        [AllowedProperties.MetadataDocumentLiabilityCap]: t({
            message: 'Limitation of liability amount',
            comment: 'Insights: Metric allowed fields: MetadataDocumentLiabilityCap',
        }),
        [AllowedProperties.MetadataDocumentNoticePeriodDays]: t({
            message: 'Notice Period (Days)',
            comment: 'Insights: Metric allowed fields: MetadataDocumentNoticePeriodDays',
        }),
        [AllowedProperties.MetadataDocumentNoticePeriodMonths]: t({
            message: 'Notice Period (Months)',
            comment: 'Insights: Metric allowed fields: MetadataDocumentNoticePeriodMonths',
        }),
        [AllowedProperties.MetadataDocumentOriginalTermMonths]: t({
            message: 'Original Term (Months)',
            comment: 'Insights: Metric allowed fields: MetadataDocumentOriginalTermMonths',
        }),
        [AllowedProperties.MetadataDocumentPaymentTermsDaysFromInvoiceDate]: t({
            message: 'Payment terms days from invoice date',
            comment: 'Insights: Metric allowed fields: MetadataDocumentPaymentTermsDaysFromInvoiceDate',
        }),
        [AllowedProperties.MetadataDocumentRenewalTermMonths]: t({
            message: 'Renewal Term (Months)',
            comment: 'Insights: Metric allowed fields: MetadataDocumentRenewalTermMonths',
        }),
        [AllowedProperties.MetadataDocumentTotalContractCost]: t({
            message: 'Total Contract Cost',
            comment: 'Insights: Metric allowed fields: MetadataDocumentTotalContractCost',
        }),
        [AllowedProperties.MetadataDocumentTotalContractValue]: t({
            message: 'Total Contract Value',
            comment: 'Insights: Metric allowed fields: MetadataDocumentTotalContractValue',
        }),
    };
    return translations[key] || fallback;
}
export function getMetricTypeTranslations(key) {
    if (!key) {
        return '';
    }
    const translations = {
        [SearchAggregationMetricType.Avg]: t({
            message: 'Average',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
        [SearchAggregationMetricType.Max]: t({
            message: 'Maximum',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
        [SearchAggregationMetricType.Min]: t({
            message: 'Minimum',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
        [SearchAggregationMetricType.Sum]: t({
            message: 'Sum',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
        [SearchAggregationMetricType.Cardinality]: t({
            message: 'Cardinality',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
        [SearchAggregationMetricType.ValueCount]: t({
            message: 'Value Count',
            comment: 'Insights - Translation for Metric aggregation - type input',
        }),
    };
    return translations[key];
}
