import { Plugin, ButtonView } from 'ckeditor5';
import { AssistantSelectionCommand } from './selectioncommand';
export const ASSISTANT_COMPONENT_NAME = 'inlineAssistant';
export class AssistantUI extends Plugin {
    static get pluginName() {
        return 'inlineAssistant';
    }
    constructor(editor) {
        super(editor);
        this.handleButtonClick = () => {
            const { editor } = this;
            editor.execute(AssistantSelectionCommand.pluginName);
        };
        this.config = editor.config.get('assistant');
    }
    init() {
        const { editor, config, handleButtonClick } = this;
        editor.ui.componentFactory.add(ASSISTANT_COMPONENT_NAME, () => {
            var _a;
            const assistantSelectionCommand = editor.commands.get(AssistantSelectionCommand.pluginName);
            const button = new ButtonView();
            button.bind('isEnabled').to(assistantSelectionCommand);
            button.set({
                withText: Boolean(config === null || config === void 0 ? void 0 : config.label),
                label: config === null || config === void 0 ? void 0 : config.label,
                icon: (_a = config === null || config === void 0 ? void 0 : config.icon) !== null && _a !== void 0 ? _a : undefined,
                class: 'pleditor-toolbar-fa-icon-button',
            });
            button.on('execute', handleButtonClick);
            return button;
        });
    }
}
