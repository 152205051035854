import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { useActiveDocument } from 'shared/domains/documents';
import * as Sentry from 'shared/domains/sentry';
import { QUERY } from './query';
import { isFailedSubcodeHandled } from './utils';
const ONE_SECOND = 1000;
const POLL_INTERVAL = 1.5 * ONE_SECOND;
const MAX_POLLING_TIME = 2 * 60 * ONE_SECOND;
const POLLING_STATUSES = [SignRequestStatus.Creating, SignRequestStatus.Cancelling];
const EXECUTE_LIFE_CYCLE_METHODS_ON_POLLING = true;
export const usePollLastSignRequest = () => {
    var _a;
    const { id: documentId } = useActiveDocument();
    const [paused, setPaused] = useState(false);
    const { addToast } = useToast();
    const { setCreateSignRequestFailedSubcode } = useEsigning();
    const { data, startPolling, stopPolling } = useQuery(QUERY, {
        variables: {
            documentId,
        },
        notifyOnNetworkStatusChange: EXECUTE_LIFE_CYCLE_METHODS_ON_POLLING,
        onCompleted(latestData) {
            var _a;
            const { status: latestStatus, statusSubcode } = ((_a = latestData === null || latestData === void 0 ? void 0 : latestData.document) === null || _a === void 0 ? void 0 : _a.lastSignRequest) || {};
            if (isFailedSubcodeHandled(statusSubcode)) {
                setCreateSignRequestFailedSubcode(statusSubcode);
            }
            if (latestStatus === SignRequestStatus.Failed && !isFailedSubcodeHandled(statusSubcode)) {
                const errorCode = statusSubcode ? `${latestStatus}:${statusSubcode}` : latestStatus;
                Sentry.captureException(errorCode);
            }
            if (latestStatus === SignRequestStatus.Pending) {
                addToast({
                    appearance: 'success',
                    title: t({
                        message: `Successfully sent for e-signing.`,
                        comment: 'Message for when a user successfully sent an e-signing request',
                    }),
                });
            }
            if (latestStatus === SignRequestStatus.Cancelled) {
                addToast({
                    appearance: 'info',
                    title: t({
                        message: 'Successfully cancelled e-signing request.',
                        comment: 'Message for when a user successfully cancelled an e-signing request',
                    }),
                });
            }
        },
    });
    const resume = () => setPaused(false);
    const { status } = ((_a = data === null || data === void 0 ? void 0 : data.document) === null || _a === void 0 ? void 0 : _a.lastSignRequest) || {};
    const shouldPoll = status && POLLING_STATUSES.includes(status);
    useEffect(() => {
        if (!paused && shouldPoll) {
            startPolling(POLL_INTERVAL);
            const pollingTimeout = setTimeout(() => {
                stopPolling();
                setPaused(true);
            }, MAX_POLLING_TIME);
            return () => {
                stopPolling();
                clearTimeout(pollingTimeout);
            };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paused, shouldPoll]);
    return { paused, resume };
};
