import { Plugin } from 'ckeditor5';
import { InsertAttachmentWidgetCommand } from '../attachmentWidget/command';
import { ATTACHMENT_CONSTANTS } from '../attachmentWidget/editing';
import { SELECT_ATTACHMENT_CONSTANTS } from '../insertAttachmentWidget/editing';
const EMBED_EVENT = 'change:embeddedAttachmentsCounter';
export class AttachmentStore extends Plugin {
    static get pluginName() {
        return 'AttachmentStore';
    }
    constructor(editor) {
        super(editor);
        this.currentScrollEmbedId = '';
        this.currentScrollEmbedIndex = 0;
        /**
         *
         * @description A counter for the number of times an attachment has been embedded in the document
         */
        this.embeddedAttachmentsCounter = {};
        /**
         *
         * @param id
         * @returns the number of times the attachment with the given id has been embedded in the document
         */
        this.getEmbedCount = (id) => this.embeddedAttachmentsCounter[id] || 0;
        /**
         *
         * @param id
         * @description Increases the number of times the attachment with the given id has been embedded in the document
         * @fires Fires the `change:embeddedAttachmentsCounter` event
         */
        this.increaseEmbedCount = (id) => {
            const count = this.getEmbedCount(id);
            this.embeddedAttachmentsCounter[id] = count + 1;
            this.fire(EMBED_EVENT, Object.assign({}, this.embeddedAttachmentsCounter));
        };
        /**
         *
         * @param id
         * @description Decreases the number of times the attachment with the given id has been embedded in the document
         * @fires Fires the `change:embeddedAttachmentsCounter` event
         */
        this.decreaseEmbedCount = (id) => {
            const count = this.getEmbedCount(id);
            this.embeddedAttachmentsCounter[id] = Math.max(count - 1, 0);
            this.fire(EMBED_EVENT, Object.assign({}, this.embeddedAttachmentsCounter));
        };
        /**
         *
         * @param id
         * @returns true if the attachment with the given id has been embedded in the document
         */
        this.isEmbedded = (id) => this.getEmbedCount(id) > 0;
        /**
         *
         * @param id
         * @description Adds or increases the number of times the attachment with the given id has been embedded in the document
         */
        this.addEmbed = (id) => {
            this.increaseEmbedCount(id);
        };
        /**
         *
         * @param id
         * @description Decreases the number of times the attachment with the given id has been embedded in the document
         */
        this.removeEmbed = (id) => {
            this.decreaseEmbedCount(id);
        };
        /**
         *
         * @description Sets the `embeddedIds` array to the ids of all attachments embedded in the document
         * @fires Fires the `change:embeddedAttachmentsCounter` event
         */
        this.setEmbeds = () => {
            const root = this.editor.model.document.getRoot();
            if (!root) {
                return;
            }
            const counter = {};
            for (const { item } of this.editor.model.createRangeIn(root)) {
                if (item.is('element', ATTACHMENT_CONSTANTS.modelElement)) {
                    const id = item.getAttribute('id');
                    const attachmentCounter = counter[id];
                    counter[id] = attachmentCounter !== undefined ? attachmentCounter + 1 : 1;
                }
            }
            this.embeddedAttachmentsCounter = counter;
            this.fire(EMBED_EVENT, counter);
        };
        /**
         *
         * @param id
         * @param index
         * @returns the model node of the attachment with the given id and index
         */
        this.getEmbeddedNode = (id, index) => {
            const root = this.editor.model.document.getRoot();
            if (!root) {
                return null;
            }
            let currentIndex = 0;
            for (const { item } of this.editor.model.createRangeIn(root)) {
                if (item.is('element', ATTACHMENT_CONSTANTS.modelElement)) {
                    const modelId = item.getAttribute('id');
                    if (modelId === id) {
                        if (currentIndex === index) {
                            return item;
                        }
                        currentIndex += 1;
                    }
                }
            }
            return null;
        };
        /**
         *
         * @param id
         * @param resetIndex
         * @description Scrolls to the attachment with the given id and index
         */
        this.scrollToEmbed = (id, resetIndex = false) => {
            if (resetIndex) {
                this.currentScrollEmbedIndex = 0;
            }
            else if (this.currentScrollEmbedId === id) {
                this.currentScrollEmbedIndex += 1;
            }
            else {
                this.currentScrollEmbedId = id;
                this.currentScrollEmbedIndex = 0;
            }
            const node = this.getEmbeddedNode(id, this.currentScrollEmbedIndex);
            if (!node) {
                if (resetIndex) {
                    return;
                }
                this.scrollToEmbed(id, true);
            }
            else {
                const viewNode = this.editor.editing.mapper.toViewElement(node);
                const dom = this.editor.editing.view.domConverter.viewToDom(viewNode);
                dom.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        };
        const config = this.editor.config.get('attachments');
        const attachments = (config === null || config === void 0 ? void 0 : config.attachments) || [];
        this.set({ attachments });
    }
    afterInit() {
        this.setEmbeds();
    }
    /**
     *
     * @param id
     * @returns the attachment with the given id
     */
    get(id) {
        return this.attachments.find(attachment => attachment.id === id);
    }
    /**
     *
     * @param attachment
     * @description Adds an attachment to the store
     */
    add(attachment) {
        const attachments = [...this.attachments, attachment];
        this.set({ attachments });
    }
    /**
     *
     * @param attachment
     * @description Updates an attachment in the store. Also updates the attachment widgets connected to the attachment
     */
    update(attachment) {
        const attachments = this.attachments.map(a => {
            if (a.id === attachment.id) {
                return attachment;
            }
            return a;
        });
        this.set({ attachments });
        const root = this.editor.model.document.getRoot();
        if (!root) {
            return;
        }
        for (const { item } of this.editor.model.createRangeIn(root)) {
            // Find the attachment widgets connected to the updated attachment
            if (item.is('element', ATTACHMENT_CONSTANTS.modelElement) &&
                item.getAttribute('id') === attachment.id) {
                this.editor.editing.reconvertItem(item);
            }
            // Find select attachment widgets
            if (item.is('element', SELECT_ATTACHMENT_CONSTANTS.modelElement)) {
                this.editor.editing.reconvertItem(item);
            }
        }
    }
    /**
     *
     * @param id
     * @description Removes an attachment from the store. Also removes the attachment widgets connected to the attachment
     */
    remove(id) {
        const attachments = this.attachments.filter(attachment => attachment.id !== id);
        this.set({ attachments });
        const root = this.editor.model.document.getRoot();
        if (!root) {
            return;
        }
        this.editor.model.enqueueChange({ isLocal: false, isUndoable: false }, writer => {
            const range = writer.createRangeIn(root);
            const itemsToRemove = [];
            for (const { item } of range.getWalker()) {
                if (item.is('element', ATTACHMENT_CONSTANTS.modelElement) &&
                    item.getAttribute('id') === id) {
                    // writer.remove(item); <- removal here won't work. We are in an iterator.
                    itemsToRemove.push(item);
                }
            }
            for (const item of itemsToRemove) {
                const position = writer.createPositionBefore(item);
                const empty = writer.createElement('paragraph');
                writer.remove(item);
                writer.insert(empty, position);
                writer.setSelection(empty, 'in');
            }
        });
        for (const { item } of this.editor.model.createRangeIn(root)) {
            // Find select attachment widgets
            if (item.is('element', SELECT_ATTACHMENT_CONSTANTS.modelElement)) {
                this.editor.editing.reconvertItem(item);
            }
        }
    }
    /**
     *
     * @param attachment
     * @description Adds an attachment to the store and inserts it into the document
     */
    insert(attachment) {
        this.add(attachment);
        this.addEmbed(attachment.id);
        this.editor.execute(InsertAttachmentWidgetCommand.pluginName, attachment);
    }
}
