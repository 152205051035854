import { t } from '@lingui/macro';
import { Box, DialogHeader, IconButton, TextInput } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function PickerHeader(props) {
    const { title, subtitle, searchQuery, search, clearSearch, onClose } = props;
    const handleChange = (event) => {
        search(event.target.value);
    };
    const handleReset = () => {
        clearSearch();
    };
    return (<DialogHeader title={title} subtitle={subtitle} action={<Box alignItems="center" gap="md">
          <TextInput adornmentLeft={<Falcon icon="magnifying-glass"/>} adornmentRight={<IconButton size="xs" appearance="ghost" aria-label={t({ comment: 'Reset search tooltip', message: 'Reset' })} onClick={handleReset}>
                <Falcon icon="trash"/>
              </IconButton>} value={searchQuery} onChange={handleChange} placeholder={t({ comment: 'Search placeholder', message: 'Search...' })}/>
          <IconButton $round appearance="ghost" onClick={onClose}>
            <Falcon icon="xmark"/>
          </IconButton>
        </Box>}/>);
}
