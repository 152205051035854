import { Command } from 'ckeditor5';
export class AssistantResetCommand extends Command {
    static get pluginName() {
        return 'assistantReset';
    }
    constructor(editor, state) {
        super(editor);
        this.state = state;
    }
    refresh() {
        const { state } = this;
        this.isEnabled = Boolean(state.marker);
    }
    execute() {
        const { editor } = this;
        const { model } = editor;
        this.state.clear(model);
    }
}
