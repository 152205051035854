import { Plugin } from 'ckeditor5';
import { AssistantSelection } from './editing';
import { AssistantUI } from './ui';
import './augmentation';
export { AssistantReplaceCommand } from './replacecommand';
export { AssistantResetCommand } from './resetcommand';
export { AssistantSelectionCommand } from './selectioncommand';
export { ASSISTANT_COMPONENT_NAME } from './ui';
/**
 * This plugin handles communication between the `Assistant` and `Pleditor`
 *
 * Example: We listen for assistant suggestions and update the corresponding content in the document
 */
export class Assistant extends Plugin {
    static get pluginName() {
        return 'Assistant';
    }
    static get requires() {
        return [AssistantSelection, AssistantUI];
    }
}
