import { Fragment, useState } from 'react';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, Badge, Box, Chip, IconButton, Menu, MenuBody, MenuItem, MenuList, Tooltip, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { EsigningAttachmentKind } from 'shared/domains/apollo/generated/types';
import { ConfirmRemoveDialog } from './ConfirmRemoveDialog';
import { EditDialog } from './EditDialog';
import { getAttachmentSubtitle } from '../utils';
import { Container } from './styled';
export function DocumentAttachmentItem(props) {
    const { attachment, disabled = false, embeddedCount = 0, onDownload, onRename, onRemove, onToggleExcluded, onScrollToAttachment, onEmbed, } = props;
    const { name, url, kind, createdAt, updatedAt, isExcluded = false } = attachment;
    const [renderMenu, setRenderMenu] = useState(false);
    const [renderEditDialog, setRenderEditDialog] = useState(false);
    const [renderRemoveDialog, setRenderRemoveDialog] = useState(false);
    const subtitle = getAttachmentSubtitle(createdAt, updatedAt, kind);
    const showMenuActions = kind !== EsigningAttachmentKind.SignRequest;
    const isEmbedded = embeddedCount > 0;
    const hideMenu = () => setRenderMenu(false);
    const toggleMenu = () => setRenderMenu(!renderMenu);
    const hideEditDialog = () => setRenderEditDialog(false);
    const hideRemoveDialog = () => setRenderRemoveDialog(false);
    const showEditDialog = () => {
        setRenderEditDialog(true);
        hideMenu();
    };
    const showRemoveDialog = () => {
        setRenderRemoveDialog(true);
        hideMenu();
    };
    const handleRemoveAttachment = () => {
        if (onRemove) {
            onRemove();
            hideMenu();
        }
    };
    const handleRenameAttachment = (newName) => {
        if (onRename) {
            onRename(newName);
            hideMenu();
        }
    };
    const handleDownloadAttachment = () => {
        if (onDownload) {
            onDownload();
            hideMenu();
        }
    };
    const handleToggleExcluded = () => {
        if (onToggleExcluded) {
            onToggleExcluded();
            hideMenu();
        }
    };
    const handleEmbed = () => {
        if (onEmbed) {
            onEmbed(attachment);
            hideMenu();
        }
    };
    const handlePreview = (evt) => {
        evt.preventDefault();
        if (url) {
            window.open(url, '_blank');
            hideMenu();
        }
    };
    return (<Fragment>
      {renderRemoveDialog && (<ConfirmRemoveDialog embedded={isEmbedded} onCancel={hideRemoveDialog} onConfirm={handleRemoveAttachment}/>)}
      {renderEditDialog && (<EditDialog initialName={name} onClose={hideEditDialog} onEdit={handleRenameAttachment}/>)}
      <Container>
        <AvatarWithLabel solid size="sm" title={name} subtitle={i18n._(subtitle)} appearance={isExcluded ? 'light' : 'info'}>
          <Falcon icon="paperclip"/>
        </AvatarWithLabel>
        <Box alignItems="center" gap="md">
          {isEmbedded && (<Tooltip position="left" title={t({
                comment: 'Embedded attachment tooltip',
                message: 'This attachment is embedded in the document',
            })}>
              <Chip size="small" appearance="info" label={t({ message: 'Embedded', comment: 'Embedded attachment label' })}/>
            </Tooltip>)}

          {isExcluded && (<Tooltip position="left" title={t({
                comment: 'Excluded attachment tooltip',
                message: 'This attachment will not be included when sent for eSigning',
            })}>
              <Chip size="small" appearance="secondary" label={t({ message: 'Excluded', comment: 'Excluded attachment label' })}/>
            </Tooltip>)}

          <Menu open={renderMenu} onClose={hideMenu} target={<IconButton $round size="xs" appearance="ghost" onClick={toggleMenu}>
                <Falcon icon="ellipsis-v"/>
              </IconButton>}>
            <MenuBody>
              <MenuList>
                {isEmbedded && onScrollToAttachment && showMenuActions && (<MenuItem disabled={disabled} onClick={onScrollToAttachment}>
                    <Falcon icon="magnifying-glass"/>
                    <Trans comment="Menu label for scrolling to attachment">Show in document</Trans>
                    <Box ml="auto">
                      <Badge size="small" appearance="secondary" value={embeddedCount}/>
                    </Box>
                  </MenuItem>)}
                {onEmbed && showMenuActions && (<MenuItem disabled={disabled || Boolean(isExcluded)} onClick={handleEmbed}>
                    <Falcon icon="paperclip"/>
                    <Trans comment="Menu label for embedding attachment">Embed</Trans>
                  </MenuItem>)}
                {url && (<MenuItem as="a" href={url} target="_blank" onClick={handlePreview}>
                    <Falcon icon="eye"/>
                    <Trans comment="Menu label for opening attachment link">Preview</Trans>
                  </MenuItem>)}
                {onDownload && (<MenuItem onClick={handleDownloadAttachment}>
                    <Falcon icon="down-to-line"/>
                    <Trans comment="Menu label for downloading attachment">Download</Trans>
                  </MenuItem>)}
                {onRename && showMenuActions && (<MenuItem disabled={disabled} onClick={showEditDialog}>
                    <Falcon icon="pencil"/>
                    <Trans comment="Menu label for editing attachment name">Edit name</Trans>
                  </MenuItem>)}
                {onRemove && showMenuActions && (<MenuItem disabled={disabled} onClick={showRemoveDialog}>
                    <Falcon icon="trash"/>
                    <Trans comment="Menu label for removing attachment">Remove</Trans>
                  </MenuItem>)}
                {onToggleExcluded && showMenuActions && (<MenuItem disabled={disabled} onClick={handleToggleExcluded}>
                    {isExcluded ? (<Fragment>
                        <Falcon icon="check"/>
                        <Trans comment="Menu label for including attachment">Include</Trans>
                      </Fragment>) : (<Fragment>
                        <Falcon icon="xmark"/>
                        <Trans comment="Menu label for excluding attachment">Exclude</Trans>
                      </Fragment>)}
                  </MenuItem>)}
              </MenuList>
            </MenuBody>
          </Menu>
        </Box>
      </Container>
    </Fragment>);
}
