export function getTextFromRange(range) {
    return Array.from(range.getItems()).reduce((rangeText, node) => {
        if (!(node.is('$text') || node.is('$textProxy'))) {
            return rangeText;
        }
        return rangeText + node.data;
    }, '');
}
export function getTextFromSelection(selection) {
    const range = selection.getFirstRange();
    if (!range) {
        return '';
    }
    return getTextFromRange(range);
}
