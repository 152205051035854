import { Command, Collection, uid } from 'ckeditor5';
export class HighlightAtCommand extends Command {
    static get pluginName() {
        return 'highlightAt';
    }
    constructor(editor, state) {
        super(editor);
        this.state = state;
    }
    execute(props) {
        const { paths } = props;
        const { editor, state } = this;
        const { model } = editor;
        model.change(writer => {
            // Get the root element
            const root = model.document.getRoot();
            // Find text nodes by path
            const textNodes = [];
            for (const path of paths) {
                const node = root === null || root === void 0 ? void 0 : root.getNodeByPath(path);
                if (node === null || node === void 0 ? void 0 : node.is('$text')) {
                    textNodes.push(node);
                }
            }
            if (textNodes.length) {
                const highlightNodes = new Collection();
                for (const node of textNodes) {
                    const markerId = uid();
                    const marker = writer.addMarker(`highlightMarker:${markerId}`, {
                        usingOperation: false,
                        affectsData: false,
                        range: writer.createRangeOn(node),
                    });
                    highlightNodes.add({
                        id: markerId,
                        marker,
                    });
                }
                state.clear(model);
                state.highlights.addMany(highlightNodes);
                state.anchor = highlightNodes.get(0);
            }
        });
    }
}
