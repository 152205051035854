import { forwardRef } from 'react';
import { t } from '@lingui/macro';
import { Chip, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ButtonLoader, InputContainer } from './styled';
export const InsightsChatInput = forwardRef((props, ref) => {
    const { value, onChange, onSubmit, error, loading, disabled } = props;
    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            onSubmit();
        }
    };
    return (<InputContainer>
      <Falcon icon="sparkles" $size={16}/>
      <input type="text" ref={ref} placeholder={t({
            message: 'Ask to generate a widget...',
            comment: 'Insights chat - textarea - label',
        })} onChange={onChange} value={value} onKeyDown={handleOnKeyDown} disabled={disabled}/>

      {!error && (<Chip appearance="glass" variant="outline" label={t({ message: 'BETA', comment: 'Insights chat - Beta label' })}/>)}

      {error && <Chip appearance="danger" label={error}/>}

      <ButtonLoader $loading={loading}>
        <IconButton disabled={loading || !value} type="button" size="xs" onClick={onSubmit} $round appearance="glass">
          <Falcon icon="arrow-right"/>
        </IconButton>
      </ButtonLoader>
    </InputContainer>);
});
